(function() {
    'use strict';

    angular.module('beacon.app')
        .service('TenantDataService', TenantDataService);

    function TenantDataService(Restangular, $http) {

        /**
         * Returns tenants list
         * @param {object} data
         */
        function getTenants(data) {
            return Restangular.all('tenants').getList(data);
        }

        /**
         * Returns a list of tenants admins
         */
        function getTenantsAdmins() {
            return Restangular.all('tenants/admins').getList();
        }

        /**
         * Returns tenants map
         */
        function getTenantsMap(paramsObj) {
            return new Promise((resolve, reject) => {
                getTenants(paramsObj).then((data) => {
                        var tenantsMap = {};
                        _.forEach(data, (tenant) => {
                            tenantsMap[tenant.id] = tenant;
                            tenantsMap[tenant.id].subdomainsMap = {};
                            _.forEach(tenant.subdomains, (subdomain) => {
                                tenantsMap[tenant.id].subdomainsMap[subdomain.id] = subdomain;
                            });
                        });
                        resolve(tenantsMap);
                    });
            });
        }

        /**
         * Deletes tenant by it`s id
         *
         * @param {number} id
         */
        function deleteTenant(id) {
            return Restangular.one('tenants', id).remove();
        }

        /**
         * Creates new tenant
         *
         * @param {object} data
         */
        function createTenant(data) {
            return Restangular.all('tenants')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Updates tenant
         *
         * @param {object} tenant
         * @param {object} data
         */
        function updateTenant(tenant, data) {
            data.append('_method', 'PUT');
            return tenant.customPOST(data, '', {}, {'Content-Type': undefined});
        }

        /**
         * Returns subdomains list
         * @param {number} tenantId
         */
        function getSubdomains(tenantId, data) {
            return Restangular.all(['tenants', tenantId, 'subdomains'].join('/')).getList(data);
        }

        /**
         * Returns a list of tenant subdomains admins
         */
        function getTenantSubdomainsAdmins(tenantId) {
            let url = ['tenants', tenantId, 'subdomains', 'admins'].join('/');
            return Restangular.all(url).getList();
        }

        /**
         * Returns a list of users (with ROLE_USER) for specific subdomain
         */
        function getTenantSubdomainUsers(tenantId, subdomainId) {
            let url = ['tenants', tenantId, 'subdomains', subdomainId, 'users'].join('/');
            return Restangular.all(url).getList();
        }

        /**
         * Deletes subdomain and reassign content by it`s id.
         *
         * @param {number} tenantId
         * @param {number} subdomainId
         * @param {number|undefined} reassignSubdomainId
         */
        function deleteSubdomain(tenantId, subdomainId, reassignSubdomainId) {
            let url = ['tenants', tenantId, 'subdomains', subdomainId,].join('/');
            return Restangular.all(url ).post({ reassignSubdomainId: reassignSubdomainId });
        }

        /**
         * Creates new subdomain
         *
         * @param {number} tenantId
         * @param {object} data
         */
        function createSubdomain(tenantId, data) {
            return Restangular.all(['tenants', tenantId, 'subdomains'].join('/')).post(data);
        }

        /**
         * Returns a list of tenants where current User is TENANT_ADMIN. If user is SYS_ADMIn then returns all tenants.
         */
        function getTenantsWhereUserIsTenantAdmin() {
            return Restangular.all('tenants/user-is-tenant-admin').getList();
        }

        function getAvailableApps() {
            return Restangular.all('tenants/available-apps').getList();
        }

        /**
         * Set a default content for screens
         *
         * @param contentId
         * @returns {*}
         */
        function setDefaultScreenContent(contentId) {
            return Restangular.one('tenants/setDefaultScreenContent/', contentId).get();
        }

        /**
         * Get a default content for screens
         *
         * @returns {*}
         */
        function getDefaultScreenContent() {
            return Restangular.one('tenants/getDefaultScreenContent').get();
        }

        /**
         * @return {Promise<TimetableHafasOptions>}
         */
        function getTimetableHafasPreset() {
            return $http.get('/api/tenant/timetable-hafas-preset').then(response => {
                return response.data;
            });
        }

        return {
            getTenants,
            getTenantsAdmins,
            getTenantsMap,
            deleteTenant,
            createTenant,
            updateTenant,
            getSubdomains,
            getTenantSubdomainsAdmins,
            getTenantSubdomainUsers,
            deleteSubdomain,
            createSubdomain,
            getTenantsWhereUserIsTenantAdmin,
            getAvailableApps,
            setDefaultScreenContent,
            getDefaultScreenContent,
            getTimetableHafasPreset,
        };
    }
})();
