(function() {
    'use strict';

    angular.module('beacon.app')
        .controller('VouchersListController', VouchersListController);

    function VouchersListController(
        $state,
        $stateParams,
        StorageFactory,
        VoucherService,
        UtilitiesService
    ) {
        const vm = this;

        vm.STORAGE = new StorageFactory.Storage('Vouchers', true);

        vm.STATUS_INACTIVE = 0;
        vm.STATUS_ACTIVE = 1;
        vm.STATUS_DELETED = 3;

        vm.create = create;

        vm.listData = {
            columns: [
                {
                    name: 'NAME',
                    class: 'voucher-list__column-title',
                    width: '25',
                    title: 'name_cms'
                },
                {
                    name: 'Status',
                    class: 'voucher-list__column-status',
                    width: '15',
                    translate: true,
                    title: item => {
                        switch(item.status) {
                            case vm.STATUS_INACTIVE:
                                return 'INACTIVE';
                            case vm.STATUS_ACTIVE:
                                return 'ACTIVE';
                            case vm.STATUS_DELETED:
                                return 'DELETED';
                            default:
                                return 'UNDEFINED';
                        }
                    }
                },
                {
                    name: 'VALID_FROM',
                    width: '15',
                    title: item => moment(item.valid_from).format('LL HH:mm')
                },
                {
                    name: 'VALID_TILL',
                    width: '15',
                    title: item => moment(item.valid_till).format('LL HH:mm')
                },
                {
                    name: '',
                    class: 'text-center no-padding',
                    title: item => {
                        return item.image_ref ? `<img src="${item.image_ref}" height="41">` : '';
                    }
                },
            ],
            buttons: {
                width: '15',
                minWidth: '150px',
                items: [
                    {
                        class: 'deleteBtn',
                        callback: _delete,
                        isVisible: (btn, item) => item.status !== vm.STATUS_DELETED,
                        permissionAction: 'delete'
                    },
                    {
                        class: 'editBtn',
                        callback: _edit,
                        isVisible: (btn, item) => item.status !== vm.STATUS_DELETED,
                        permissionAction: 'modify'
                    },
                ]
            },
            updateCallback: _updateVouchersList,
            generatePermissionName: _generatePermissionName
        };

        /**
         * Create voucher button handler
         */
        function create() {
            $state.go('app.loyaltyVoucherAdd');
        }

        /**
         * Delete button handler
         */
        function _delete($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            VoucherService.remove(item.id)
                .then(() => {
                    _updateVouchersList(vm.paginationParams.page, vm.paginationParams.itemsPerPage, true);
                })
                .catch(console.error.bind(console));
        }

        /**
         * Edit button handler
         */
        function _edit($event, item) {
            $event.preventDefault();
            $event.stopPropagation();

            const data = item.plain();

            $state.go('app.loyaltyVoucherEdit', {
                data,
                paginationData: {
                    page: vm.paginationParams.page,
                    itemsPerPage: vm.paginationParams.itemsPerPage,
                    count: vm.totalItems
                },
                type: 'edit'
            });
        }

        /**
         * Updating vouchers list
         *
         * @param {number} page
         * @param {number} itemsPerPage
         * @param {boolean} forceApi
         * @private
         */
        function _updateVouchersList(page = vm.paginationParams.page,
                                     itemsPerPage = vm.paginationParams.itemsPerPage,
                                     forceApi = false) {
            const vouchersAll = vm.STORAGE.get('vouchers');
            if (!forceApi && vouchersAll !== undefined) {
                _applyVouchersToScope(vouchersAll, page, itemsPerPage);
            } else {
                VoucherService.getList()
                    .then(response => {
                        vm.STORAGE.set('vouchers', response);
                        _applyVouchersToScope(response, page, itemsPerPage);
                    })
                    .catch(console.error.bind(console));
            }
        }

        /**
         * Applying filtering and pagination parameters to elements, and adding elements list to scope
         *
         * @param {array} elements
         * @param {number} page
         * @param {number} itemsPerPage
         */
        function _applyVouchersToScope(elements, page, itemsPerPage) {
            vm.totalItems = elements.length;
            vm.vouchers = UtilitiesService.getArrayPage(elements, page, itemsPerPage);
            vm.paginationParams = {page, itemsPerPage};
        }

        /**
         * From short name to permission full name
         *
         * @param {object} button
         * @returns {string}
         */
        function _generatePermissionName(button) {
            return 'can_' + button.permissionAction + '_vouchers';
        }
    }
}());