(function() {
    'use strict';

    angular
        .module('beacon.app')
        .directive('beaconMainSideMenu', beaconMainSideMenu);

    function beaconMainSideMenu() {
        return {
            restrict: 'A',
            templateUrl: '/assets/views/common/directives/main-side-menu/main-side-menu.tpl.html',
            replace: true,
            controller: BeaconMainSideMenuController,
            controllerAs: 'mainSideMenu',
            bindToController: true
        };
    }

    function BeaconMainSideMenuController (
        $scope,
        $state,
        $translate,
        PermissionsService,
        UserDataService
    ) {
        const vm = this;

        const {
            actions,
            isCampaignMenuItemVisible,
            isLocationMenuItemVisible,
            isContentGroupMenuItemVisible,
            isContentManagementMenuItemVisible,
            generateCampaignMobilePermission,
            generateCampaignPushPermission,
            generateScreenCampaignPermission,
            isLoyaltyMenuItemVisible,
            isTrmPoiMenuItemVisible,
            isPoiMenuItemVisible,
            isStatisticsVisible,
            isFeedbackStatisticsVisible,
            generateScenarioPermission,
            generateHabitPermission,
            generateSegmentPermission,
            isScreensDashboardVisible,
            isCustomersMenuItemVisible,
            isCarParksMenuItemVisible,
            isIotMenuItemVisible,
            isShareParkOperationsMenuItemVisible,
            isPricingMenuItemVisible,
        } = PermissionsService;

        // public methods
        vm.isCampaignMenuItemVisible = isCampaignMenuItemVisible;
        vm.isLocationMenuItemVisible = isLocationMenuItemVisible;
        vm.isContentGroupMenuItemVisible = isContentGroupMenuItemVisible;
        vm.isContentManagementMenuItemVisible = isContentManagementMenuItemVisible;
        vm.isCustomersMenuItemVisible = isCustomersMenuItemVisible;
        vm.isCarParksMenuItemVisible = isCarParksMenuItemVisible;
        vm.isIotMenuItemVisible = isIotMenuItemVisible;
        vm.isLoyaltyMenuItemVisible = isLoyaltyMenuItemVisible;
        vm.isTrmPoiMenuItemVisible = isTrmPoiMenuItemVisible;
        vm.isPoiMenuItemVisible = isPoiMenuItemVisible;
        vm.isStatisticsVisible = isStatisticsVisible;
        vm.isFeedbackStatisticsVisible = isFeedbackStatisticsVisible;
        vm.isScreensDashboardVisible = isScreensDashboardVisible;
        vm.isShareParkOperationsMenuItemVisible = isShareParkOperationsMenuItemVisible;
        vm.isPricingMenuItemVisible = isPricingMenuItemVisible;
        vm.onTenantLabelClick = onTenantLabelClick;

        // public properties
        vm.viewCampaignMobilePermission = generateCampaignMobilePermission(actions.view);
        vm.viewCampaignPushPermission = generateCampaignPushPermission(actions.view);
        vm.viewScreenCampaignPermission = generateScreenCampaignPermission(actions.view);
        vm.viewScenarioPermission = generateScenarioPermission(actions.view);
        vm.viewHabitPermission = generateHabitPermission(actions.view);
        vm.viewSegmentPermission = generateSegmentPermission(actions.view);

        vm.state = $state;
        vm.groups = {
            account: false,
            campaign: false,
            content: false,
            loyalty: false,
            poi: false,
            trmPoi: false,
            statistics: false,
            carPark: false,
            operations: false,
            customers: false,
            pricing: false,
        };
        vm.manualUrl = null;
        vm.manualSpUrl = null;

        // private properties
        const groupRoutes = {
            account: [
                'app.profile',
                'app.domains'
            ],
            campaign: [
                'app.campaign',
                'app.addCampaign',
                'app.editCampaign',
                'app.pushCampaign',
                'app.addPushCampaign',
                'app.editPushCampaign',
                'app.screenCampaign',
                'app.addScreenCampaign',
                'app.editScreenCampaign',
                'app.segment',
                'app.addSegment',
                'app.editSegment',
                'app.habit',
                'app.editHabit',
                'app.addHabit',
                'app.scenario',
                'app.newScenario',
                'app.editScenario',
            ],
            content: [
                'app.content',
                'app.contentGroup',
                'app.contentManagement',
                'app.addContent',
                'app.editContent',
                'app.addContentGroup',
                'app.editContentGroup',
                'app.addContentManagement',
                'app.editContentManagement',
                'app.dynamicContentDashboard',
                'app.configureMCT',
                'app.unity3dModels',
                'app.addUnity3dModel',
                'app.editUnity3dModel',
                'app.contentScreenCampaigns',
            ],
            loyalty: [
                'app.loyaltyDashboard',
                'app.loyaltyCampaigns',
                'app.loyaltyCampaignsAdd',
                'app.loyaltyCampaignsEdit',
                'app.loyaltyTierGroups',
                'app.loyaltyTierGroupsAdd',
                'app.loyaltyTierGroupsEdit',
                'app.loyaltyTiers',
                'app.loyaltyTiersAdd',
                'app.loyaltyTiersEdit',
                'app.loyaltyVouchersList',
                'app.loyaltySponsorList',
                'app.loyaltySponsorAdd',
                'app.loyaltySponsorEdit',
            ],
            trmPoi: [
                'app.trmPoiElements',
                'app.addTrmPoiElement',
                'app.editTrmPoiElement',
                'app.trmPoiCategories',
                'app.addTrmPoiCategory',
                'app.editTrmPoiCategory',
            ],
            poi: [
                'app.poiElements',
                'app.addPoiElement',
                'app.editPoiElement',
                'app.poiTours',
                'app.addPoiTour',
                'app.editPoiTour',
                'app.poiImport',
                'app.poiCheckDoublets'
            ],
            statistics: [
                'app.statistics',
                'app.statisticsDashboard',
            ],
            location: [
                'app.location',
                'app.screensDashboard',
                'app.screenHealth',
                'app.addGeofence',
                'app.editGeofence',
                'app.addGeofenceGroup',
                'app.editGeofenceGroup',
                'app.addScreenGroup',
                'app.editScreenGroup',
                'app.addBeaconGroup',
                'app.editBeaconGroup',
                'app.addScreen',
                'app.editScreen',
                'app.manageScreens',
            ],
            carPark: [
                'app.carParks',
                'app.addCarPark',
                'app.editCarPark',
                'app.shareParkCapacity',
                'app.lotManagement',
                'app.capStatistics',
                'app.capConcierge',
                'app.capEnforcement',
            ],
            iotDevices: [
                'app.iotDashboard',
                'app.iotDevices',
                'app.addIotDevice',
                'app.editIotDevice',
                'app.addIotEntity',
                'app.editIotEntity',
                'app.chargingStations',
                'app.addChargingStation',
                'app.editChargingStation',
            ],
            operations: [
                'app.operationsDashboard',
                'app.capReservations',
                'app.operationsRevenues',
                'app.operationsLegal',
                'app.editLegalText',
                'app.addLegalText',
                'app.billingConfigurations',
                'app.addBillingConfiguration',
                'app.editBillingConfiguration',
                'app.legalTextVersions',
                'app.shareParkFAQ',
                'app.addShareParkFAQ',
                'app.editShareParkFAQ',
                'app.feedbackForms',
                'app.feedbackFormsEdit',
                'app.shareParkReports',
                'app.multiGuide',
            ],
            customers: [
                'app.shareParkCustomers',
                'app.addShareParkCustomer',
                'app.editShareParkCustomer',
                'app.shareParkGuests',
                'app.addShareParkGuests',
                'app.editShareParkGuests',
                'app.shareParkCustomerCommunication',
                'app.addCustomerCommunication',
                'app.editCustomerCommunication',
                'app.shareParkServiceProviders',
                'app.addShareParkServiceProvider',
                'app.editShareParkServiceProvider',
                'app.shareParkSublets',
                'app.addShareParkSublet',
                'app.editShareParkSublet',
                'app.shareParkCompanies',
                'app.addShareParkCompany',
                'app.editShareParkCompany',
                'app.shareParkProfiles',
                'app.addShareParkProfile',
                'app.editShareParkProfile',
                'app.shareParkProfileConfigs',
                'app.addShareParkProfileConfig',
                'app.editShareParkProfileConfig',
                'app.shareParkProfileRules',
                'app.addShareParkProfileRules',
                'app.editShareParkProfileRules',
            ],
            pricing: [
                'app.shareParkDiscounts',
                'app.newDiscount',
                'app.editDiscount',
                'app.shareParkProducts',
                'app.newProduct',
                'app.editProduct',
                'app.shareParkTariffs',
                'app.newTariff',
                'app.editTariff',
                'app.shareParkTariffModels',
                'app.newTariffModel',
                'app.editTariffModel',
            ]
        };

        init();

        /**
         * Initialization method
         * Watching for boolean value in groups and open/close each depends of it's value
         */
        function init() {
            $scope.$watch('mainSideMenu.state.current.name', function () {
                _.forOwn(vm.groups, function (value, groupName) {
                    vm.groups[groupName] = _.get(groupRoutes, groupName, []).indexOf($state.current.name) !== -1;
                });
            });

            UserDataService.currentUserInfo().then(response => {
                vm.manualUrl = response.subdomain.tenant.manualRef;
                vm.manualSpUrl = response.subdomain.tenant.manualSpRef;
            });
        }

        /**
         * Tenant label click handler
         */
        function onTenantLabelClick () {
            $state.go('app.domains', {});
        }
    }
}());
