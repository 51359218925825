(function() {
    'use strict';

    angular.module('trmServices').service('RequestHelper', RequestHelperService);

    function RequestHelperService(
        $timeout,
    ) {
        return {
            withRetry,
        };

        /**
         * Tries to call requestFn in "retryTimeout" ms between each attempt in maximum amount of attempts ("maxAttempts")
         * 
         * @param {{
         *     requestFn: function(): Promise<any>,
         *     retryTimeout?: number,
         *     maxAttempts?: number
         * }} params requestFn: request async function,
         *           retryTimeout: how much ms to wait between request attempts,
         *           maxAttempts: maximum allowed number of request attempts
         * @returns {Promise<any>}
         */
        function withRetry({ requestFn, retryTimeout = 5000, maxAttempts = 10 }) {
            if (!requestFn) {
                throw new Error('Missing request promise in options.requestFn property');
            }

            let totalAttempts = 1;

            return new Promise((resolve, reject) => {
                const makeRequest = () => {
                    if (totalAttempts >= maxAttempts) {
                        reject();
                        return;
                    }

                    requestFn()
                        .then(resolve)
                        .catch(error => {
                            console.error(error);

                            $timeout(() => {
                                totalAttempts++;
                                makeRequest();
                            }, retryTimeout);
                        });
                };

                makeRequest();
            });
        }

    }
})();
