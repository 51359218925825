(function() {
    'use strict';

    angular.module('beacon.app')
        .service('LocationDataService', LocationDataService);

    function LocationDataService(
        Restangular,
        $state,
        $auth,
        StorageFactory,
        ScreenHelper,
    ) {
        return {
            getLocationsAll,
            getLocationsAllWithTenants,
            getLocations,
            getControllerLocation,
            getSections,
            createLocationsGroup,
            getLocationGroups,
            deleteLocationGroup,
            getControllerDetails,
            getAllControllerDetails,
            getBeaconDetails,
            syncTags,
            getScreenFilterTypes,
            downloadScreensUrl,
            getScreensAssignedContent,
            getScreens,
            getScreensWithDetails,
            getBeacons,
            requestScreenshot,
            getScreenshot,
            getLocationCampaigns,
            getStationPoles,
            getScreenHealth,
        };

        // Public methods

        /**
         * Returns all locations list
         */
        function getLocationsAll(forceReload, tenantId) {
            return new Promise((resolve, reject) => {
                let STORAGE = StorageFactory.Storage('Main');
                let locationsAll = STORAGE.get('locationsAll');
                if (forceReload || !locationsAll) {
                    var requestData = {};
                    if (forceReload) {
                        requestData['reload'] = true;
                    }
                    if (tenantId) {
                        requestData['tenant-id'] = tenantId;
                    }
                    Restangular.one('locations-all').get(requestData).then((data) => {
                        locationsAll = _.keyBy(data, 'id');
                        resolve(STORAGE.set('locationsAll', locationsAll));
                    });
                } else {
                    resolve(locationsAll);
                }
            });
        }

        function getLocationsAllWithTenants() {
            return Restangular.one('locations-all/with-tenants').get();
        }

        /**
         * Return locations list
         * @param {object} data
         */
        function getLocations(data) {
            return Restangular.one('locations').get(data);
        }

        function getControllerLocation(controllerId) {
            return Restangular.one('controllerLocation/' + controllerId).get();
        }

        /**
         * Return sections list
         * @param {object} data
         */
        function getSections(data) {
            return Restangular.one('sections').get(data);
        }

        /**
         * Screens
         * @param {string} mode - 'dashboard'
         */
        function getScreens(mode = null) {
            const params = {type: 'screen'};

            if (mode) {
                params.mode = mode;
            }

            return getSections(params);
        }

        function getBeacons() {
            return getSections({type: 'beacon'});
        }


        /**
         * Creates new locations group
         * @param {object} data
         */
        function createLocationsGroup(data) {
            return Restangular.all('location/group').post(data);
        }

        /**
         * Return all screen groups list
         */
        function getLocationGroups(data) {
            return Restangular.all('location/group').getList(data);
        }

        /**
         * Deletes location group by it`s id
         *
         * @param {object} id
         */
        function deleteLocationGroup(id) {
            return Restangular.one('location/group', id).remove();
        }

        /**
         * Gets controller details
         */
        function getControllerDetails() {
            return Restangular.one('controller/details').get();
        }

        /**
         * Gets controller screenshot
         */
        function requestScreenshot(hash) {
            return Restangular.one(`controller/request-screenshot?hash=${hash}`).get();
        }

        /**
         * Gets controller screenshot
         */
        function getScreenshot(hash) {
            return Restangular.one(`controller/get-screenshot?hash=${hash}`).get();
        }

        /**
         * Gets all-controller details
         */
        function getAllControllerDetails() {
            return Restangular.one('controller/all-details').get();
        }

        /**
         * Gets beacon details
         */
        function getBeaconDetails() {
            return Restangular.one('beacon/details').get();
        }

        /**
         * Sync tags with locations
         *
         * @param locationId
         * @param tagIds - array of tag ids
         * @returns {*}
         */
        function syncTags(locationId, tagIds) {
            return Restangular.all('location/tags/' + locationId).post(tagIds);
        }

        /**
         * Get screen filter properties
         *
         * @returns {object}
         */
        function getScreenFilterTypes() {
            return Restangular.all('screens/filter_types').getList();
        }

        /**
         * Download screens URL *.csv file
         * @param data
         */
        function downloadScreensUrl(data) {
            return Restangular.all('location/download-screens-url').post(JSON.stringify(data));
        }

        /**
         * Get screens assigned content
         */
        function getScreensAssignedContent(controllerRef) {
            return Restangular.one(`location/get-screens-assigned-content/${controllerRef}`).get();
        }

        /**
         * Get all screens with controller details
         */
        function getScreensWithDetails() {
            return new Promise((resolve, reject) => {
                Promise.all([
                    getScreens(),
                    getControllerDetails()
                ]).then(response => {
                    let screens = response[0].plain().list;
                    const screenControllers = response[1].plain().list;

                    screens = screens.map(screen =>
                        ScreenHelper.mergeScreenWithControllerData(screen, screenControllers)
                    );

                    resolve(screens);
                }).catch(err => reject(err));
            })
        }

        /**
         * Download screens URL *.csv file
         * @param locationId
         */
        function getLocationCampaigns(locationId) {
            return Restangular.all('location/campaigns').get(locationId);
        }

        /**
         *
         * @param stationId
         * @return {*}
         */
        function getStationPoles(stationId) {
            return Restangular.one('location/station/poles').get({ stationId });
        }

        /**
         * Gets screens health stats
         */
        function getScreenHealth(period) {
            return Restangular.one(`screen-health/locations?period=${period}`).get();
        }
    }
})();
