(function() {
    'use strict';

    angular.module('trmTimetableHafas').service('JourneysBuffer',
        /**
         * @param $q
         * @param $interval
         * @param $timeout
         * @param {MgateService} MgateService
         * @param {JourneysProviderService} JourneysProviderService
         * @param {JourneyFactory} JourneyFactory
         * @param {LogService} LogService
         * @param {RequestHelper} RequestHelper
         * @param {MultiStationsHelper} MultiStationsHelper
         * @param {LocationsProviderService} LocationsProviderService
         */
        function(
            $q,
            $timeout,
            $interval,
            MgateService,
            JourneysProviderService,
            JourneyFactory,
            LogService,
            RequestHelper,
            MultiStationsHelper,
            LocationsProviderService,
        ) {
            const log = (...args) => {
                LogService.colored('#ec453f', '#FFF', 'Journeys Buffer |', ...args);
            };

            class JourneysBuffer {
                // For debug purposes
                _freeze = false;        // TODO: don't forget to set false
                _simulateHim = false;   // TODO: don't forget to set false

                /** @type {JourneysBufferOptions} */
                _options = {};

                /** @type {boolean} */
                _isOnline;

                /** @type {string|null} */
                _bufferErrorMessage;

                /** @type {LocationProviderStation[]} */
                _stations = [];

                /** @type {Journey[]} */
                _journeysBuffer = [];

                /** @type {MgateHim[]} */
                _himsBuffer = [];

                _intervals = {
                    journeysBuffer: null,
                    offlineUpdate: null,
                }

                /**
                 * @type {Object<string, string>[]} - replacement map for station name
                 */
                _replacements;

                /**
                 * @typedef {Object} JourneysBufferOptions
                 *
                 * @property {number} subdomainId
                 * @property {MctConfig} mctConfig
                 * @property {TimetableHafasOptions} timetableOptions
                 * @property {ScreenController} screenController
                 * @property {number} limit
                 * @property {number[]} stationsFilter
                 * @property {object} callbacks
                 * @property {function(Journey[], MgateHim[], boolean): void} callbacks.onChange
                 * @property {function(string): void} callbacks.onError
                 */

                /**
                 * @param {JourneysBufferOptions} options
                 */
                constructor(options) {
                    log('CONSTRUCTING buffer with limit', options.limit);
                    Object.assign(this._options, options);

                    $q.all([
                        RequestHelper.withRetry({
                            requestFn: this._loadPoles.bind(this),
                            maxAttempts: 100,
                        }),
                        RequestHelper.withRetry({
                            requestFn: this._loadProductParams.bind(this),
                            maxAttempts: 100,
                        }),
                    ])
                    .then(() => {
                        this._validate();
                        void this._initBuffer();
                    })
                    .catch(error => this._errorHandler(error));
                }

                destroy() {
                    log('DESTROYING buffer with limit', this._options.limit);
                    $interval.cancel(this._intervals.journeysBuffer);
                    $timeout.cancel(this._intervals.offlineUpdate);
                }

                _validate() {
                    if (!this._stations.length) {
                        throw new JourneysBufferExpectedError('Station not provided');
                    }
                }

                /**
                 * @param {Error} error
                 * @private
                 */
                _errorHandler(error) {
                    switch (error.name) {
                        case 'JourneysBufferExpectedError':
                        case 'MgateExpectedError':
                            this._bufferErrorMessage = error.message;
                            break;
                        case 'MgateUnexpectedError':
                        default:
                            this._bufferErrorMessage = 'Sorry, we have trouble with the data connection';
                            console.error(error);
                    }

                    this._options.callbacks.onError(this._bufferErrorMessage);
                }

                /**
                 * Init the journeys buffer
                 *
                 * @return {Promise<void>}
                 * @private
                 */
                _initBuffer() {
                    if (!this._freeze) {
                        this._intervals.journeysBuffer = $interval(
                            () => this._updateJourneysAndHimBuffer(),
                            this._options.timetableOptions.interval * 1000,
                        );
                    }

                    return this._updateJourneysAndHimBuffer();
                }

                /**
                 * Fills the journeys buffer with journeys
                 *
                 * @return {Promise<MgateStationBoardResponseCore>}
                 * @private
                 */
                _updateJourneysAndHimBuffer() {
                    log('Updating buffers... Limit:', this._options.limit);

                    const options = this._options;
                    const viaStopsAmount = this._getViaStopsAmount();

                    return JourneysProviderService.journeys({
                        subdomainId: options.subdomainId,
                        stations: this._stations,
                        viaStops: viaStopsAmount && viaStopsAmount + 2, // we filter 1st and last station in the JourneyFactory
                        mctConfig: options.mctConfig,
                        screenController: options.screenController,
                        timetableOptions: options.timetableOptions,
                        productParams: this._productParams,
                        limit: options.limit,
                    })
                        .then(response => {
                            this._isOnline = true;
                            this._bufferErrorMessage = null;

                            const { journeys, hims } = JourneyFactory.generateJourneys(
                                response,
                                options.mctConfig,
                                this._replacements,
                                this._productParams,
                                viaStopsAmount,
                            );

                            this._journeysBuffer = journeys;

                            if (this._simulateHim) {
                                this._himsBuffer = this._getHimSimulation();
                            } else {
                                this._himsBuffer = this._journeysBuffer.length
                                    ? hims
                                    : [];
                            }

                            log(`Buffer updated. Got ${journeys.length} journeys`
                                + `(limit ${options.limit}) and ${hims.length} HIM messages`);

                            log('Journeys buffer', journeys);
                            log('HIM buffer', hims);

                            this._setFutureJourneys();
                        })
                        .catch(error => {
                            this._isOnline = false;

                            // keep it working in offline mode if buffer is not empty
                            if (this._isBufferEmpty()) {
                                return this._errorHandler(error);
                            }

                            console.error(error);
                        });
                }

                /**
                 * Add journeys to the view from buffer
                 *
                 * @private
                 */
                _setFutureJourneys() {
                    const futureJourneys = this._getFutureJourneys();

                    log('Future journeys:', futureJourneys.length);

                    if (!this._freeze) {
                        this._scheduleNextOfflineUpdate(futureJourneys);
                    }

                    this._options.callbacks.onChange(futureJourneys, this._himsBuffer, this._isOnline);
                }

                /**
                 * @return {boolean}
                 * @private
                 */
                _isBufferEmpty() {
                    return !this._getFutureJourneys().length;
                }

                /**
                 * @return {Journey[]}
                 * @private
                 */
                _getFutureJourneys() {
                    return this._journeysBuffer.filter(item => item.isFuture())
                }

                /**
                 * Schedule next update of journeys from buffer to view
                 *
                 * @param {Journey[]} futureJourneys
                 * @private
                 */
                _scheduleNextOfflineUpdate(futureJourneys) {
                    const secondsToDepartureArray = futureJourneys
                        .map(item => item.getMsToRealtimePastState())
                        .filter(ms => ms > 0);
                    const msToUpdate = secondsToDepartureArray.length && Math.min(...secondsToDepartureArray);

                    log('Next expired journeys update in', msToUpdate / 1000, 'seconds');

                    if (this._intervals.offlineUpdate) {
                        $timeout.cancel(this._intervals.offlineUpdate);
                    }

                    if (msToUpdate) {
                        this._intervals.offlineUpdate = $timeout(
                            () => {
                                log('Removing expired journeys');
                                this._setFutureJourneys();
                            },
                            msToUpdate
                        );
                    }
                }

                /**
                 * Load pole objects from the backend
                 *
                 * @return {Promise<void>}
                 * @private
                 */
                _loadPoles() {
                    return LocationsProviderService.getStationsWithPoles({
                        mctConfig: this._options.mctConfig,
                        screenController: this._options.screenController,
                        stationsFilter: this._options.stationsFilter,
                        subdomainId: this._options.subdomainId,
                    }).then(response => {
                        this._stations = response;
                    });
                }

                _loadProductParams() {
                    return MgateService.productParams(this._options.subdomainId)
                        .then(response => {
                            this._replacements = response.stationNameReplacementMap;
                            this._productParams = response;
                        });
                }

                _getViaStopsAmount() {
                    const directionTableElement = this._options.timetableOptions.elements.find(item => item.type === 'direction');

                    switch (true) {
                        case !directionTableElement:
                            return 0;
                        case angular.isNumber(this._options.mctConfig.showViaStops):
                            return this._options.mctConfig.showViaStops;
                        case angular.isNumber(directionTableElement.viaStops):
                            return directionTableElement.viaStops;
                        default:
                            return 0;
                    }
                }

                /**
                 * @return {MgateHim[]|{head: string, hid: number, text: string}[]}
                 * @private
                 */
                _getHimSimulation() {
                    return [
                        {
                            hid: 1,
                            head: 'What is Lorem Ipsum?',
                            text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                        },
                        {
                            hid: 2,
                            head: 'Why do we use it?',
                            text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
                        },
                        {
                            hid: 3,
                            head: 'Where does it come from?',
                            text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.'
                        },
                    ]
                }
            }

            class JourneysBufferExpectedError extends Error {
                constructor(message) {
                    super(message);
                    this.name = 'JourneysBufferExpectedError';
                }
            }

            return {
                JourneysBuffer,
            };
        });
})();
