(function() {
    'use strict';

    angular.module('beacon.app')
        .component('screensTab', {
            templateUrl: '/assets/views/location/tabs/location-tab/screens-tab/screens-tab.tpl.html',
            controller: ScreensTab,
            bindings: {
                subtab: '<',
                searchName: '<',
                onSelect: '=',
                onSubTabChange: '<',
            }
        });

    function ScreensTab(
        $scope,
        $q,
        $translate,
        locationTabState,
        LocationDataService,
        ScreensFilterPopup,
        GeofenceLocationPickerPopup,
        LOCATION_GROUPS_TYPES,
        ACTIVATION_STATUS,
    ) {
        const vm = this;

        vm.$onInit = init;

        const subtabs = locationTabState.views.LOCATIONS.tabs.SCREENS.subtabs;

        vm.SCREENS_TAB = subtabs.SCREEN_ITEMS.index;
        vm.SCREEN_GROUPS_TAB = subtabs.SCREEN_GROUPS.index;
        vm.screens = [];
        vm.allMarkers = [];
        vm.filteredScreens = [];
        vm.screenGroups = [];
        vm.screensCount = 0;
        vm.groupsCount = 0;

        vm.onTabChange = onTabChange;
        vm.onFiltersClick = onFiltersClick;
        vm.onSelectByGeofenceClick = onSelectByGeofenceClick;
        vm.clearFilters = clearFilters;

        function init() {
            vm.currentTab = angular.isNumber(vm.subtab) ? vm.subtab : vm.SCREENS_TAB;
            loadScreens();
        }

        function onTabChange(newTab) {
            vm.currentTab = newTab;
            vm.onSubTabChange(vm.currentTab);
        }

        /**
         * Filter screens button click handler
         */
        function onFiltersClick() {
            ScreensFilterPopup.show(vm.filteredScreens)
                .then(response => {
                    if (response) {
                        vm.filteredScreens = response;
                        vm.screensCount = vm.filteredScreens.length;
                        updateMarkers();
                    }
                });
        }

        /**
         * Select by geofence button click handler
         */
        function onSelectByGeofenceClick() {
            GeofenceLocationPickerPopup.show(vm.screens, vm.filteredScreens).then(result => {
                vm.filteredScreens = result.locations;
                vm.screensCount = vm.filteredScreens.length;
                updateMarkers();
            })

        }

        /**
         * Loads screens list
         * @return {*}
         */
        function loadScreens() {
            $q.all([
                LocationDataService.getScreens('assets'),
                LocationDataService.getControllerDetails(),
            ]).then(response => {
                const screens = response[0];
                const details = response[1];

                vm.screens = screens.plain().list;

                vm.screensDetails = details.list;
                vm.screens.forEach(item => {
                    const details = vm.screensDetails.find(detailsItem =>
                        item.controllerRef === parseInt(detailsItem.id)
                    );

                    if (details.locationStationIds) {
                        details.locationStationIds = angular.fromJson(details.locationStationIds);
                    }

                    if (!!details) {
                        item.name = details.name;
                        item.lat = details.screen.lat ? parseFloat(details.screen.lat) : null;
                        item.lng = details.screen.lng ? parseFloat(details.screen.lng) : null;
                        item.markers = item.lat && item.lng
                            ? [{
                                label: details.name,
                                position: {
                                    lat: item.lat,
                                    lng: item.lng
                                },
                            }] : [];
                        item.details = details;
                    }
                });

                const statusList = [
                    ACTIVATION_STATUS.ACTIVE.value,
                    ACTIVATION_STATUS.INACTIVE.value
                ];
                vm.filteredScreens = vm.screens = vm.screens.filter(
                    item => item.details && statusList.includes(item.details.status)
                );
                vm.screensCount = vm.filteredScreens.length;
                updateMarkers();
            });
        }

        /**
         * Clears filters
         */
        function clearFilters() {
            vm.filteredScreens = vm.screens;
            vm.screensCount = vm.screens.length;
            updateMarkers();
        }

        /**
         * Update markers
         */
        function updateMarkers() {
            if (!vm.filteredScreens.length) {
                return;
            }
            vm.allMarkers = vm.filteredScreens.reduce(
                (accum, currentValue) => currentValue.markers ?
                    [...accum, ...currentValue.markers] : accum
                , []);
        }

        $scope.$watch(
            () => vm.searchName,
            searchName => {
                if (vm.filteredScreens.length !== vm.screens.length
                    && searchName) {
                    clearFilters()
                }
            }
        );

    }
})();
