(function() {
    'use strict';

    angular.module('beacon.app')
        .config(routes)
        .run(bindCheckUserAuthentication);

    function bindCheckUserAuthentication($rootScope, $state, LoginService, $timeout, UserUtilitiesService, PopupService) {
        var exceptions = ['signup', 'resetPassword', 'resetPasswordForm', 'chooseTenant'];

        $rootScope.$on('$locationChangeSuccess', function() {
            $timeout(() => {
                const isAuthorized = LoginService.isLoggedIn();
                const needAuth = exceptions.indexOf($state.current.name) === -1;

                if (needAuth && !isAuthorized) {
                    $state.go('login');
                    less.modifyVars();
                }

                if (isAuthorized && UserUtilitiesService.isPasswordExpired()) {
                    $state.go('app.profile', {
                        passwordExpiredAlert: true
                    });
                }
            });
        });
    }

    function routes($stateProvider, $urlRouterProvider, $locationProvider, $authProvider) {
        $urlRouterProvider
            .otherwise(function($injector, $state) {
                return $state.$$path !== '/' ? $state.$$path : '/content' + window.location.search;
            });

        $stateProvider
            .state('app', {
                abstract: true,
                url: '/',
                templateUrl: '/assets/views/main/main.tpl.html',
                controller: 'MainController',
                controllerAs: 'mainController',
                resolve: {
                    initialSetup: ($q, StorageFactory, LoginService, PermissionsService, UserDataService, UserUtilitiesService) => {
                        if (!LoginService.isLoggedIn()) return;

                        /**
                         * Note: At first init the Main Storage and then load Permissions and User data before
                         * initialization of MainController.
                         */
                        new StorageFactory.Storage('Main', true);

                        //  Note: As Angular service is singleton then we manually calling init method of our service.
                        UserUtilitiesService.init();

                        return $q.all([
                            PermissionsService.loadPermissions(),
                            UserDataService.loadUserData(),
                        ]);
                    }
                }
            })
            .state('login', {
                url: '/login',
                params: {
                    message: ''
                },
                templateUrl: '/assets/views/login/login.tpl.html',
                controller: 'LoginController',
                controllerAs: 'loginController'
            })
            .state('signup', {
                url: '/signup',
                templateUrl: '/assets/views/login/signup/signup.tpl.html',
                controller: 'SignupController',
                controllerAs: 'signupController'
            })
            .state('resetPassword', {
                url: '/reset-password',
                template: '<reset-password></reset-password>',
            })
            .state('resetPasswordForm', {
                url: '/reset-password/:token',
                template: '<reset-password-form></reset-password-form>',
            })
            .state('chooseTenant', {
                url: '/chooseTenant',
                params: {
                    data: {}
                },
                templateUrl: '/assets/views/login/select-tenant/select-tenant.tpl.html',
                controller: 'ChooseTenantController',
                controllerAs: 'chooseTenantController'
            })
            .state('app.tenants', {
                url: 'tenants',
                views: {
                    main: {
                        templateUrl: '/assets/views/tenants/tenants.tpl.html',
                        controller: 'TenantsController',
                        controllerAs: 'tenantsController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/tenants/toolbar/toolbar.tpl.html',
                        controller: 'TenantsToolbarController',
                        controllerAs: 'tenantsToolbarController'
                    }
                }
            })
            .state('app.editTenant', {
                url: 'tenants/edit',
                views: {
                    main: {
                        template: '<new-tenant></new-tenant>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/tenants/tabs/tenants-tab/new/toolbar/toolbar.tpl.html',
                        controller: 'TenantAddToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.newTenant', {
                url: 'tenants/new',
                views: {
                    main: {
                        template: '<new-tenant></new-tenant>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/tenants/tabs/tenants-tab/new/toolbar/toolbar.tpl.html',
                        controller: 'TenantAddToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.users', {
                url: 'users',
                views: {
                    main: {
                        templateUrl: '/assets/views/users/users.tpl.html',
                        controller: 'UsersController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addUser', {
                url: 'users/new',
                views: {
                    main: {
                        templateUrl: '/assets/views/users/new/new-user.tpl.html',
                        controller: 'NewUserController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/users/new/toolbar/toolbar.tpl.html',
                        controller: 'NewUserToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.editUser', {
                url: 'users/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/users/new/new-user.tpl.html',
                        controller: 'NewUserController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/users/new/toolbar/toolbar.tpl.html',
                        controller: 'NewUserToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.location', {
                url: 'location',
                views: {
                    main: {
                        templateUrl: '/assets/views/location/location.tpl.html',
                        controller: 'LocationController',
                        controllerAs: 'locationController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/toolbar/toolbar.tpl.html',
                        controller: 'LocationToolbarController',
                        controllerAs: 'locationToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                }
            })
            .state('app.screensDashboard', {
                url: 'location/screens-dashboard',
                views: {
                    main: {
                        template: '<screens-dashboard></screens-dashboard>'
                    }
                }
            })
            .state('app.screenHealth', {
                url: 'location/screen-health',
                views: {
                    main: {
                        template: '<screen-health></screen-health>',
                    },
                },
                params: {
                    paginationData: null,
                }
            })
            .state('app.addGeofence', {
                url: 'location/geofence/new',
                views: {
                    main: {
                        templateUrl: '/assets/views/location/new-geofence/new-geofence.tpl.html',
                        controller: 'GeofenceAddController',
                        controllerAs: 'geofenceAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/new-geofence/toolbar/toolbar.tpl.html',
                        controller: 'GeofenceAddToolbarController',
                        controllerAs: 'geofenceAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.editGeofence', {
                url: 'location/geofence/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/location/new-geofence/new-geofence.tpl.html',
                        controller: 'GeofenceAddController',
                        controllerAs: 'geofenceAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/new-geofence/toolbar/toolbar.tpl.html',
                        controller: 'GeofenceAddToolbarController',
                        controllerAs: 'geofenceAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.addGeofenceGroup', {
                url: 'location/geofence/group/new',
                views: {
                    main: {
                        templateUrl: '/assets/views/location/new-geofence-group/new-geofence-group.tpl.html',
                        controller: 'GeofenceGroupAddController',
                        controllerAs: 'geofenceGroupAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/new-geofence-group/toolbar/toolbar.tpl.html',
                        controller: 'GeofenceGroupAddToolbarController',
                        controllerAs: 'geofenceGroupAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.editGeofenceGroup', {
                url: 'location/geofence/group/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/location/new-geofence-group/new-geofence-group.tpl.html',
                        controller: 'GeofenceGroupAddController',
                        controllerAs: 'geofenceGroupAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/new-geofence-group/toolbar/toolbar.tpl.html',
                        controller: 'GeofenceGroupAddToolbarController',
                        controllerAs: 'geofenceGroupAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.addScreenGroup', {
                url: 'location/screen/group/new',
                views: {
                    main: {
                        templateUrl: '/assets/views/location/new-location-group/new-location-group.tpl.html',
                        controller: 'LocationGroupAddController',
                        controllerAs: 'locationGroupAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/new-location-group/toolbar/toolbar.tpl.html',
                        controller: 'LocationGroupAddToolbarController',
                        controllerAs: 'locationGroupAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                    group_type: null
                }
            })
            .state('app.editScreenGroup', {
                url: 'location/screen/group/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/location/new-location-group/new-location-group.tpl.html',
                        controller: 'LocationGroupAddController',
                        controllerAs: 'locationGroupAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/new-location-group/toolbar/toolbar.tpl.html',
                        controller: 'LocationGroupAddToolbarController',
                        controllerAs: 'locationGroupAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                    group_type: null
                }
            })
            .state('app.addBeaconGroup', {
                url: 'location/beacon/group/new',
                views: {
                    main: {
                        templateUrl: '/assets/views/location/new-location-group/new-location-group.tpl.html',
                        controller: 'LocationGroupAddController',
                        controllerAs: 'locationGroupAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/new-location-group/toolbar/toolbar.tpl.html',
                        controller: 'LocationGroupAddToolbarController',
                        controllerAs: 'locationGroupAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                    group_type: null
                }
            })
            .state('app.editBeaconGroup', {
                url: 'location/beacon/group/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/location/new-location-group/new-location-group.tpl.html',
                        controller: 'LocationGroupAddController',
                        controllerAs: 'locationGroupAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/new-location-group/toolbar/toolbar.tpl.html',
                        controller: 'LocationGroupAddToolbarController',
                        controllerAs: 'locationGroupAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                    group_type: null
                }
            })
            .state('app.statistics', {
                url: 'statistic',
                views: {
                    main: {
                        templateUrl: '/assets/views/statistic/statistic.tpl.html',
                        controller: 'StatisticsController',
                        controllerAs: 'statisticsController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/statistic/toolbar/toolbar.tpl.html',
                        controller: 'StatisticsToolbarController',
                        controllerAs: 'statisticsToolbarController'
                    }
                },
                params: {
                    data: null
                }
            })
            .state('app.statisticsDashboard', {
                url: 'statistic/dashboard',
                views: {
                    main: {
                        templateUrl: '/assets/views/statistic/dashboard/dashboard.tpl.html',
                        controller: 'StatisticsDashboardController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.content', {
                url: 'content',
                views: {
                    main: {
                        templateUrl: '/assets/views/content/elements/elements.tpl.html',
                        controller: 'ElementsController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.contentGroup', {
                url: 'content/groups',
                views: {
                    main: {
                        templateUrl: '/assets/views/content/groups/groups.tpl.html',
                        controller: 'ContentGroupController',
                        controllerAs: 'contentGroupController'
                    }
                }
            })
            .state('app.contentManagement', {
                url: 'content/management',
                views: {
                    main: {
                        templateUrl: '/assets/views/content/management/management.tpl.html',
                        controller: 'ContentManagementController',
                        controllerAs: '$ctrl'
                    }
                }
            })
            .state('app.editContentManagement', {
                url: 'content/management/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/content/management/edit/edit-management.tpl.html',
                        controller: 'ContentManagementEditController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/content/management/toolbar/toolbar.tpl.html',
                        controller: 'ContentManagementEditToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    sections: [],
                    sectionGroups: [],
                    type: 'edit'
                }
            })
            .state('app.addContentGroup', {
                url: 'content/groups/new',
                views: {
                    main: {
                        templateUrl: '/assets/views/content/groups/new/new-group.tpl.html',
                        controller: 'ContentGroupAddController',
                        controllerAs: 'contentGroupAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/content/groups/toolbar/toolbar.tpl.html',
                        controller: 'ContentGroupAddToolbarController',
                        controllerAs: 'contentGroupAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    type: 'new',
                }
            })
            .state('app.editContentGroup', {
                url: 'content/groups/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/content/groups/new/new-group.tpl.html',
                        controller: 'ContentGroupAddController',
                        controllerAs: 'contentGroupAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/content/groups/toolbar/toolbar.tpl.html',
                        controller: 'ContentGroupAddToolbarController',
                        controllerAs: 'contentGroupAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    type: 'edit'
                }
            })
            .state('app.addContent', {
                url: 'content/new',
                views: {
                    main: {
                        templateUrl: '/assets/views/content/elements/new/new-content.tpl.html',
                        controller: 'ContentAddController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/content/elements/toolbar/toolbar.tpl.html',
                        controller: 'ContentToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    initialData: null,
                    data: null,
                    paginationData: null,
                    type: 'new',
                    copy: false,
                    redirect: null,
                }
            })
            .state('app.editContent', {
                url: 'content/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/content/elements/new/new-content.tpl.html',
                        controller: 'ContentAddController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/content/elements/toolbar/toolbar.tpl.html',
                        controller: 'ContentToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.campaign', {
                url: 'campaign/mobile',
                views: {
                    main: {
                        templateUrl: '/assets/views/campaign/mobile/mobile.tpl.html',
                        controller: 'MobileCampaignController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addCampaign', {
                url: 'campaign/mobile/new',
                views: {
                    main: {
                        templateUrl: '/assets/views/campaign/mobile/new/new-campaign.tpl.html',
                        controller: 'NewMobileCampaignController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/mobile/toolbar/toolbar.tpl.html',
                        controller: 'MobileCampaignToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                    selectedContent: []
                }
            })
            .state('app.editCampaign', {
                url: 'campaign/mobile/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/campaign/mobile/new/new-campaign.tpl.html',
                        controller: 'NewMobileCampaignController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/mobile/toolbar/toolbar.tpl.html',
                        controller: 'MobileCampaignToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.pushCampaign', {
                url: 'campaign/push',
                views: {
                    main: {
                        templateUrl: '/assets/views/campaign/push/push.tpl.html',
                        controller: 'PushCampaignController',
                        controllerAs: 'pushCampaignController'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addPushCampaign', {
                url: 'campaign/push/new',
                views: {
                    main: {
                        templateUrl: '/assets/views/campaign/push/new/new-campaign.tpl.html',
                        controller: 'NewPushCampaignController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/push/toolbar/toolbar.tpl.html',
                        controller: 'PushCampaignToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                    copy: null,
                    selectedContentId: null,
                }
            })
            .state('app.editPushCampaign', {
                url: 'campaign/push/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/campaign/push/new/new-campaign.tpl.html',
                        controller: 'NewPushCampaignController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/push/toolbar/toolbar.tpl.html',
                        controller: 'PushCampaignToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.screenCampaign', {
                url: 'campaign/screen',
                views: {
                    main: {
                        template: '<screen-campaign></screen-campaign>',
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addScreenCampaign', {
                url: 'campaign/screen/new',
                views: {
                    main: {
                        template: '<edit-screen-campaign></edit-screen-campaign>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/screen/toolbar/toolbar.tpl.html',
                        controller: 'ScreenCampaignAddToolbarController',
                        controllerAs: 'screenCampaignAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                    selectedContentId: null,
                    tab: 0,
                    redirect: null,
                }
            })
            .state('app.editScreenCampaign', {
                url: 'campaign/screen/edit',
                views: {
                    main: {
                        template: '<edit-screen-campaign></edit-screen-campaign>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/screen/toolbar/toolbar.tpl.html',
                        controller: 'ScreenCampaignAddToolbarController',
                        controllerAs: 'screenCampaignAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                    selectedContentId: null,
                    tab: 0,
                    redirect: null,
                }
            })
            .state('app.segment', {
                url: 'campaign/segment',
                views: {
                    main: {
                        templateUrl: '/assets/views/campaign/segment/segment.tpl.html',
                        controller: 'SegmentController',
                        controllerAs: 'segmentController'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addSegment', {
                url: 'campaign/segment/new',
                views: {
                    main: {
                        templateUrl: '/assets/views/campaign/segment/new/new-segment.tpl.html',
                        controller: 'SegmentAddController',
                        controllerAs: 'segmentAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/segment/new/toolbar/toolbar.tpl.html',
                        controller: 'SegmentAddToolbarController',
                        controllerAs: 'segmentAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.editSegment', {
                url: 'campaign/segment/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/campaign/segment/new/new-segment.tpl.html',
                        controller: 'SegmentAddController',
                        controllerAs: 'segmentAddController'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/segment/new/toolbar/toolbar.tpl.html',
                        controller: 'SegmentAddToolbarController',
                        controllerAs: 'segmentAddToolbarController'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.habit', {
                url: 'campaign/habit',
                views: {
                    main: {
                        template: '<habit></habit>',
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.editHabit', {
                url: 'campaign/habit/edit',
                views: {
                    main: {
                        template: '<edit-habit></edit-habit>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/habit/toolbar/toolbar.tpl.html',
                        controller: 'HabitToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.addHabit', {
                url: 'campaign/habit/new',
                views: {
                    main: {
                        template: '<edit-habit></edit-habit>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/habit/toolbar/toolbar.tpl.html',
                        controller: 'HabitToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.profile', {
                url: 'profile',
                views: {
                    main: {
                        template: '<profile></profile>'
                    }
                },
                params: {
                    passwordExpiredAlert: null
                }
            })
            .state('app.domains', {
                url: 'domains',
                views: {
                    main: {
                        templateUrl: '/assets/views/account/domains/domains.tpl.html',
                        controller: 'DomainsListController',
                        controllerAs: 'DomainsListController'
                    }
                }
            })
            .state('app.loyaltyDashboard', {
                url: 'loyalty/dashboard',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/dashboard/dashboard.tpl.html',
                        controller: 'LoyaltyDashboardController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.loyaltyCampaigns', {
                url: 'loyalty/campaigns',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/campaigns/campaigns.tpl.html',
                        controller: 'LoyaltyCampaignsController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/campaigns/toolbar/toolbar.tpl.html',
                        controller: 'LoyaltyCampaignsListToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.loyaltyCampaignsAdd', {
                url: 'loyalty/campaigns/add',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/campaigns/form/form.tpl.html',
                        controller: 'LoyaltyCampaignsFormController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/campaigns/form/toolbar/toolbar.tpl.html',
                        controller: 'LoyaltyCampaignsFormToolbarController',
                        controllerAs: '$ctrl'
                    }
                },
                params: {
                    type: 'new'
                }
            })
            .state('app.loyaltyCampaignsEdit', {
                url: 'loyalty/campaigns/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/campaigns/form/form.tpl.html',
                        controller: 'LoyaltyCampaignsFormController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/campaigns/form/toolbar/toolbar.tpl.html',
                        controller: 'LoyaltyCampaignsFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })

            /**
             * Tiers
             */
            .state('app.loyaltyTiers', {
                url: 'loyalty/tiers/types',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/tiers/tier-tabs.tpl.html',
                        controller: 'TierTabsController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    tab: 0
                }
            })
            .state('app.loyaltyTiersEdit', {
                url: 'loyalty/tiers/types/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/tiers/tier-types/form/form.tpl.html',
                        controller: 'TiersFormController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/tiers/tier-types/form/toolbar/toolbar.tpl.html',
                        controller: 'TiersFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.loyaltyTiersAdd', {
                url: 'loyalty/tiers/types/add',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/tiers/tier-types/form/form.tpl.html',
                        controller: 'TiersFormController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/tiers/tier-types/form/toolbar/toolbar.tpl.html',
                        controller: 'TiersFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })

            /**
             * Tier Groups
             */
            .state('app.loyaltyTierGroups', {
                url: 'loyalty/tiers/groups',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/tiers/tier-tabs.tpl.html',
                        controller: 'TierTabsController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    tab: 1
                }
            })
            .state('app.loyaltyTierGroupsEdit', {
                url: 'loyalty/tiers/groups/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/tiers/tier-groups/form/form.tpl.html',
                        controller: 'TierGroupsFormController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/tiers/tier-groups/form/toolbar/toolbar.tpl.html',
                        controller: 'TierGroupsFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.loyaltyTierGroupsAdd', {
                url: 'loyalty/tiers/groups/add',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/tiers/tier-groups/form/form.tpl.html',
                        controller: 'TierGroupsFormController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/tiers/tier-groups/form/toolbar/toolbar.tpl.html',
                        controller: 'TierGroupsFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })

            /**
             * Vouchers
             */
            .state('app.loyaltyVouchersList', {
                url: 'loyalty/vouchers',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/vouchers/list/list.tpl.html',
                        controller: 'VouchersListController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.loyaltyVoucherAdd', {
                url: 'loyalty/vouchers/add',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/vouchers/form/form.tpl.html',
                        controller: 'VouchersFormController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/vouchers/form/toolbar/toolbar.tpl.html',
                        controller: 'VoucherFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.loyaltyVoucherEdit', {
                url: 'loyalty/vouchers/edit',
                views: {
                    main: {
                        templateUrl: '/assets/views/loyalty/vouchers/form/form.tpl.html',
                        controller: 'VouchersFormController',
                        controllerAs: '$ctrl'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/vouchers/form/toolbar/toolbar.tpl.html',
                        controller: 'VoucherFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            /**
             * Sponsor
             */
            .state('app.loyaltySponsorList', {
                url: 'loyalty/sponsor',
                views: {
                    main: {
                        template: '<sponsor-list></sponsor-list>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.loyaltySponsorAdd', {
                url: 'loyalty/sponsor/add',
                views: {
                    main: {
                        template: '<new-sponsor></new-sponsor>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/sponsor/new/toolbar/toolbar.tpl.html',
                        controller: 'SponsorFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.loyaltySponsorEdit', {
                url: 'loyalty/sponsor/edit',
                views: {
                    main: {
                        template: '<new-sponsor></new-sponsor>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/loyalty/sponsor/new/toolbar/toolbar.tpl.html',
                        controller: 'SponsorFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.contentCampaigns', {
                url: 'content/campaigns',
                views: {
                    main: {
                        template: '<content-campaigns></content-campaigns>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/content/campaigns/toolbar/toolbar.tpl.html',
                        controller: 'ContentCampaignsToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    contentElement: null
                }
            })
            .state('app.trmPoiElements', {
                url: 'trm-poi',
                views: {
                    main: {
                        template: '<trm-poi-elements></trm-poi-elements>'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addTrmPoiElement', {
                url: 'trm-poi/new',
                views: {
                    main: {
                        template: '<new-trm-poi-element></new-trm-poi-element>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/trm-poi/elements/new/toolbar/toolbar.tpl.html',
                        controller: 'TrmPoiElementToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.editTrmPoiElement', {
                url: 'trm-poi/edit',
                views: {
                    main: {
                        template: '<new-trm-poi-element></new-trm-poi-element>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/trm-poi/elements/new/toolbar/toolbar.tpl.html',
                        controller: 'TrmPoiElementToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.trmPoiCategories', {
                url: 'trm-poi-categories',
                views: {
                    main: {
                        template: '<trm-poi-categories></trm-poi-categories>'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addTrmPoiCategory', {
                url: 'trm-poi-category/new',
                views: {
                    main: {
                        template: '<new-trm-poi-category></new-trm-poi-category>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/trm-poi/categories/new/toolbar/toolbar.tpl.html',
                        controller: 'TrmPoiCategoryToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.editTrmPoiCategory', {
                url: 'trm-poi-category/edit',
                views: {
                    main: {
                        template: '<new-trm-poi-category></new-trm-poi-category>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/trm-poi/categories/new/toolbar/toolbar.tpl.html',
                        controller: 'TrmPoiCategoryToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.poiElements', {
                url: 'poi',
                views: {
                    main: {
                        template: '<poi-elements></poi-elements>'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addPoiElement', {
                url: 'poi/new',
                views: {
                    main: {
                        template: '<new-poi-element></new-poi-element>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/poi/elements/new/toolbar/toolbar.tpl.html',
                        controller: 'PoiElementToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.editPoiElement', {
                url: 'poi/edit',
                views: {
                    main: {
                        template: '<new-poi-element></new-poi-element>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/poi/elements/new/toolbar/toolbar.tpl.html',
                        controller: 'PoiElementToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.poiTours', {
                url: 'poi/tours',
                views: {
                    main: {
                        template: '<poi-tours></poi-tours>'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addPoiTour', {
                url: 'poi/tour/new',
                views: {
                    main: {
                        template: '<new-poi-tour></new-poi-tour>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/poi/tours/new/toolbar/toolbar.tpl.html',
                        controller: 'PoiTourToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new'
                }
            })
            .state('app.editPoiTour', {
                url: 'poi/tour/edit',
                views: {
                    main: {
                        template: '<new-poi-tour></new-poi-tour>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/poi/tours/new/toolbar/toolbar.tpl.html',
                        controller: 'PoiTourToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit'
                }
            })
            .state('app.poiImport', {
                url: 'poi/import',
                views: {
                    main: {
                        template: '<poi-import></poi-import>'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.poiCheckDoublets', {
                url: 'poi/checkDoublets',
                views: {
                    main: {
                        template: '<doublets-checker></doublets-checker>'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.scenario', {
                url: 'campaign/scenario',
                views: {
                    main: {
                        template: '<scenarios-list></scenarios-list>'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.newScenario', {
                url: 'campaign/scenario/new',
                views: {
                    main: {
                        template: '<new-scenario></new-scenario>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/scenario/toolbar/toolbar.tpl.html',
                        controller: 'ScenarioToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    type: 'new'
                }
            })
            .state('app.editScenario', {
                url: 'campaign/scenario/edit',
                views: {
                    main: {
                        template: '<new-scenario></new-scenario>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/campaign/scenario/toolbar/toolbar.tpl.html',
                        controller: 'ScenarioToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    type: 'edit'
                }
            })
            .state('app.contentScreenCampaigns', {
                url: 'content/screen-campaigns',
                views: {
                    main: {
                        template: '<content-screen-campaigns></content-screen-campaigns>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/content/screen-campaigns/toolbar/toolbar.tpl.html',
                        controller: 'ContentScreenCampaignsToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    contentElement: null,
                    redirect: null,
                }
            })
            .state('app.addScreen', {
                url: 'new/screen-controller',
                views: {
                    main: {
                        template: '<new-screen></new-screen>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/new-screen/toolbar/toolbar.tpl.html',
                        controller: 'NewScreenToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    paginationData: null,
                    type: 'new',
                    previousState: 'app.location',
                }
            })
            .state('app.editScreen', {
                url: 'edit/screen-controller',
                views: {
                    main: {
                        template: '<new-screen></new-screen>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/location/new-screen/toolbar/toolbar.tpl.html',
                        controller: 'NewScreenToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                    previousState: 'app.location',
                }
            })
            .state('app.manageScreens', {
                url: 'manage-screens',
                views: {
                    main: {
                        template: '<manage-screens></manage-screens>',
                    },
                }
            })
            .state('app.dynamicContentDashboard', {
                url: 'dynamic-content-dashboard',
                views: {
                    main: {
                        template: '<dynamic-content-dashboard></dynamic-content-dashboard>',
                    },
                }
            })
            .state('app.carParks', {
                url: 'share-park/car-parks',
                views: {
                    main: {
                        template: '<car-parks></car-parks>'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addCarPark', {
                url: 'share-park/car-park/new',
                views: {
                    main: {
                        template: '<new-car-park></new-car-park>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/car-parks/new/toolbar/toolbar.tpl.html',
                        controller: 'NewCarParkToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editCarPark', {
                url: 'share-park/car-park/edit',
                views: {
                    main: {
                        template: '<new-car-park></new-car-park>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/car-parks/new/toolbar/toolbar.tpl.html',
                        controller: 'NewCarParkToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.iotDevices', {
                url: 'iot/devices',
                views: {
                    main: {
                        template: '<devices></devices>'
                    }
                },
                params: {
                    paginationData: null,
                    iotEntitiesMode: true,
                }
            })
            .state('app.addIotDevice', {
                url: 'iot/devices/new',
                views: {
                    main: {
                        template: '<new-iot-device></new-iot-device>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/iot/iot-devices/new/toolbar/toolbar.tpl.html',
                        controller: 'NewIotDeviceToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editIotDevice', {
                url: 'iot/devices/edit',
                views: {
                    main: {
                        template: '<new-iot-device></new-iot-device>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/iot/iot-devices/new/toolbar/toolbar.tpl.html',
                        controller: 'NewIotDeviceToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.addIotEntity', {
                url: 'iot/entities/new',
                views: {
                    main: {
                        template: '<new-iot-entity></new-iot-entity>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/iot/iot-devices/new-iot-entity/toolbar/toolbar.tpl.html',
                        controller: 'NewIotEntityToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editIotEntity', {
                url: 'iot/entities/edit',
                views: {
                    main: {
                        template: '<new-iot-entity></new-iot-entity>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/iot/iot-devices/new-iot-entity/toolbar/toolbar.tpl.html',
                        controller: 'NewIotEntityToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.chargingStations', {
                url: 'share-park/charging-stations',
                views: {
                    main: {
                        template: '<charging-stations></charging-stations>'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addChargingStation', {
                url: 'share-park/charging-stations/create',
                views: {
                    main: {
                        template: '<charging-stations-form></charging-stations-form>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/charging-stations/form/toolbar/toolbar.tpl.html',
                        controller: 'ChargingStationToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    paginationData: null,
                    data: null,
                    type: 'new',
                    copy: false,
                }
            })
            .state('app.editChargingStation', {
                url: 'share-park/charging-stations/edit',
                views: {
                    main: {
                        template: '<charging-stations-form></charging-stations-form>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/charging-stations/form/toolbar/toolbar.tpl.html',
                        controller: 'ChargingStationToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    paginationData: null,
                    data: null,
                    type: 'edit',
                }
            })
            .state('app.lotManagement', {
                url: 'lot-management',
                views: {
                    main: {
                        template: '<lot-management></lot-management>'
                    }
                },
            })
            .state('app.shareParkCustomers', {
                url: 'share-park/customers',
                views: {
                    main: {
                        template: '<share-park-customers></share-park-customers>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.iotDashboard', {
                url: 'iot/dashboard',
                views: {
                    main: {
                        template: '<iot-dashboard></iot-dashboard>'
                    }
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.configureMCT', {
                url: 'configure-mct',
                views: {
                    main: {
                        template: '<configure-mct></configure-mct>'
                    }
                }
            })
            .state('app.addShareParkCustomer', {
                url: 'share-park/customers/new',
                views: {
                    main: {
                        template: '<new-customer></new-customer>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/customers/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkCustomerToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editShareParkCustomer', {
                url: 'share-park/customers/edit',
                views: {
                    main: {
                        template: '<new-customer></new-customer>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/customers/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkCustomerToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.shareParkGuests', {
                url: 'share-park/guests',
                views: {
                    main: {
                        template: '<share-park-guests></share-park-guests>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addShareParkGuests', {
                url: 'share-park/guests/new',
                views: {
                    main: {
                        template: '<new-share-park-guests></new-share-park-guests>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/guests/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkGuestsToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editShareParkGuests', {
                url: 'share-park/guests/edit',
                views: {
                    main: {
                        template: '<new-share-park-guests></new-share-park-guests>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/guests/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkGuestsToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.shareParkCustomerCommunication', {
                url: 'share-park/customer-communication',
                views: {
                    main: {
                        template: '<customer-communication></customer-communication>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addCustomerCommunication', {
                url: 'share-park/customer-communication/new',
                views: {
                    main: {
                        template: '<new-customer-communication></new-customer-communication>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/customer-communication/new/toolbar/toolbar.tpl.html',
                        controller: 'CustomerCommunicationToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editCustomerCommunication', {
                url: 'share-park/customer-communication/edit',
                views: {
                    main: {
                        template: '<new-customer-communication></new-customer-communication>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/customer-communication/new/toolbar/toolbar.tpl.html',
                        controller: 'CustomerCommunicationToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.unity3dModels', {
                url: 'content/unity-3d-models',
                views: {
                    main: {
                        template: '<unity-3d-models></unity-3d-models>',
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                }
            })
            .state('app.addUnity3dModel', {
                url: 'content/unity-3d-models',
                views: {
                    main: {
                        template: '<unity-3d-model-form></unity-3d-model-form>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/content/unity-3d-models/form/toolbar/toolbar.tpl.html',
                        controller: 'Unity3dModelFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    type: 'new',
                }
            })
            .state('app.editUnity3dModel', {
                url: 'content/unity-3d-model/edit',
                views: {
                    main: {
                        template: '<unity-3d-model-form></unity-3d-model-form>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/content/unity-3d-models/form/toolbar/toolbar.tpl.html',
                        controller: 'Unity3dModelFormToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    type: 'edit',
                }
            })
            .state('app.shareParkServiceProviders', {
                url: 'share-park/service-providers',
                views: {
                    main: {
                        template: '<cap-service-providers></cap-service-providers>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addShareParkServiceProvider', {
                url: 'share-park/service-providers/new',
                views: {
                    main: {
                        template: '<new-share-park-service-provider></new-share-park-service-provider>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/service-providers/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkServiceProvidersToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editShareParkServiceProvider', {
                url: 'share-park/service-providers/edit',
                views: {
                    main: {
                        template: '<new-share-park-service-provider></new-share-park-service-provider>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/service-providers/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkServiceProvidersToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.shareParkCapacity', {
                url: 'share-park/capacity',
                views: {
                    main: {
                        template: '<car-park-capacity></car-park-capacity>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.shareParkSublets', {
                url: 'share-park/sublets',
                views: {
                    main: {
                        template: '<cap-sublets></cap-sublets>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addShareParkSublet', {
                url: 'share-park/sublets/new',
                views: {
                    main: {
                        template: '<new-share-park-sublet></new-share-park-sublet>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/sublet/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkSubletsToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editShareParkSublet', {
                url: 'share-park/sublets/edit',
                views: {
                    main: {
                        template: '<new-share-park-sublet></new-share-park-sublet>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/sublet/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkSubletsToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.capStatistics', {
                url: 'share-park/car-park/statistics',
                views: {
                    main: {
                        template: '<car-parks-statistics></car-parks-statistics>'
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/car-parks/statistics/toolbar/toolbar.tpl.html',
                        controller: 'CapStatisticsToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.shareParkDashboard', {
                url: 'share-park/dashboard',
                views: {
                    main: {
                        template: '<cap-dashboard></cap-dashboard>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.operationsRevenues', {
                url: 'share-park/operations/revenues',
                views: {
                    main: {
                        template: '<cap-operations-revenues></cap-operations-revenues>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.operationsLegal', {
                url: 'share-park/legal',
                views: {
                    main: {
                        template: '<share-park-legal></share-park-legal>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addLegalText', {
                url: 'share-park/legal/new',
                views: {
                    main: {
                        template: '<new-legal-text></new-legal-text>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/operations/legal/new/toolbar/toolbar.tpl.html',
                        controller: 'NewLegalTextToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editLegalText', {
                url: 'share-park/legal/edit',
                views: {
                    main: {
                        template: '<new-legal-text></new-legal-text>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/operations/legal/new/toolbar/toolbar.tpl.html',
                        controller: 'NewLegalTextToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.billingConfigurations', {
                url: 'share-park/billing-configurations',
                views: {
                    main: {
                        template: '<billing-configurations></billing-configurations>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addBillingConfiguration', {
                url: 'share-park/billing-configurations/new',
                views: {
                    main: {
                        template: '<new-billing-configuration></new-billing-configuration>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/operations/billing/new/toolbar/toolbar.tpl.html',
                        controller: 'NewBillingConfigurationToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editBillingConfiguration', {
                url: 'share-park/billing-configurations/edit',
                views: {
                    main: {
                        template: '<new-billing-configuration></new-billing-configuration>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/operations/billing/new/toolbar/toolbar.tpl.html',
                        controller: 'NewBillingConfigurationToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.legalTextVersions', {
                url: 'share-park/legal',
                views: {
                    main: {
                        template: '<legal-text-versions></legal-text-versions>',
                    },
                },
                params: {
                    legalText: null
                }
            })
            .state('app.capConcierge', {
                url: 'share-park/concierge',
                views: {
                    main: {
                        template: '<concierge></concierge>',
                    },
                }
            })
            .state('app.capEnforcement', {
                url: 'share-park/enforcement',
                views: {
                    main: {
                        template: '<enforcement></enforcement>',
                    },
                }
            })
            .state('app.shareParkCompanies', {
                url: 'share-park/companies',
                views: {
                    main: {
                        template: '<share-park-companies></share-park-companies>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addShareParkCompany', {
                url: 'share-park/companies/new',
                views: {
                    main: {
                        template: '<new-company></new-company>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/companies/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkCompanyToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editShareParkCompany', {
                url: 'share-park/companies/edit',
                views: {
                    main: {
                        template: '<new-company></new-company>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/companies/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkCompanyToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.operationsDashboard', {
                url: 'share-park/operations/parking-events',
                views: {
                    main: {
                        template: '<cap-parking-events></cap-parking-events>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.capReservations', {
                url: 'share-park/operations/reservations',
                views: {
                    main: {
                        template: '<cap-reservations></cap-reservations>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/operations/reservations/toolbar/toolbar.tpl.html',
                        controller: 'CapReservationsToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.shareParkFAQ', {
                url: 'share-park/faq',
                views: {
                    main: {
                        template: '<share-park-faq-configurations></share-park-faq-configurations>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addShareParkFAQ', {
                url: 'share-park/faq/new',
                views: {
                    main: {
                        template: '<new-faq-configuration></new-faq-configuration>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/operations/faq/new/toolbar/toolbar.tpl.html',
                        controller: 'NewFaqConfigurationToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editShareParkFAQ', {
                url: 'share-park/faq/edit',
                views: {
                    main: {
                        template: '<new-faq-configuration></new-faq-configuration>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/operations/faq/new/toolbar/toolbar.tpl.html',
                        controller: 'NewFaqConfigurationToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.feedbackForm', {
                url: 'feedback/:type',
                views: {
                    main: {
                        template: '<feedback></feedback>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/feedback/feedback/toolbar/toolbar.tpl.html',
                        controller: 'FeedbackToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    priority: null,
                }
            })
            .state('app.feedbackForms', {
                url: 'feedback-forms',
                views: {
                    main: {
                        template: '<feedback-forms></feedback-forms>',
                    },
                },
            })
            .state('app.feedbackFormsEdit', {
                url: 'feedback-forms/edit',
                views: {
                    main: {
                        template: '<feedback-forms-edit></feedback-forms-edit>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/feedback/feedback-forms/edit/toolbar/toolbar.tpl.html',
                        controller: 'FeedbackFormsEditToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    feedbackFormConfig: null,
                }
            })
            .state('app.shareParkReports', {
                url: 'share-park/reports',
                views: {
                    main: {
                        template: '<share-park-reports></share-park-reports>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.feedbackTickets', {
                url: 'feedback-tickets',
                views: {
                    main: {
                        template: '<feedback-tickets></feedback-tickets>',
                    },
                },
            })
            .state('app.feedbackTicket', {
                url: 'feedback-ticket/:id',
                views: {
                    main: {
                        template: '<feedback-ticket></feedback-ticket>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/feedback/feedback-ticket/toolbar/toolbar.tpl.html',
                        controller: 'FeedbackTicketToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
            })
            .state('app.feedbackCreate', {
                url: 'feedback-create',
                views: {
                    main: {
                        template: '<feedback-create></feedback-create>',
                    },
                },
            })
            .state('app.shareParkProfiles', {
                url: 'share-park/profiles',
                views: {
                    main: {
                        template: '<share-park-profiles></share-park-profiles>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addShareParkProfile', {
                url: 'share-park/profiles/new',
                views: {
                    main: {
                        template: '<new-profile></new-profile>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/profiles/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkProfileToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editShareParkProfile', {
                url: 'share-park/profiles/edit',
                views: {
                    main: {
                        template: '<new-profile></new-profile>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/profiles/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkProfileToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.shareParkProfileConfigs', {
                url: 'share-park/profile-configs',
                views: {
                    main: {
                        template: '<share-park-profile-configs></share-park-profile-configs>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addShareParkProfileConfig', {
                url: 'share-park/profile-configs/new',
                views: {
                    main: {
                        template: '<new-profile-config></new-profile-config>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/profile-configs/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkProfileConfigToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editShareParkProfileConfig', {
                url: 'share-park/profile-configs/edit',
                views: {
                    main: {
                        template: '<new-profile-config></new-profile-config>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/profile-configs/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkProfileConfigToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.shareParkProfileRules', {
                url: 'share-park/profile-rules',
                views: {
                    main: {
                        template: '<share-park-profile-rules></share-park-profile-rules>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.addShareParkProfileRules', {
                url: 'share-park/profile-rules/new',
                views: {
                    main: {
                        template: '<new-profile-rules></new-profile-rules>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/profile-rules/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkProfileRulesToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editShareParkProfileRules', {
                url: 'share-park/profile-rules/edit',
                views: {
                    main: {
                        template: '<new-profile-rules></new-profile-rules>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/customers/profile-rules/new/toolbar/toolbar.tpl.html',
                        controller: 'NewShareParkProfileRulesToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.userGroups', {
                url: 'user-groups',
                views: {
                    main: {
                        template: '<user-groups></user-groups>',
                    },
                },
            })
            .state('app.addUsersGroup', {
                url: 'user-groups/new',
                views: {
                    main: {
                        template: '<new-user-group></new-user-group>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/user-groups/new/toolbar/toolbar.tpl.html',
                        controller: 'NewUserGroupToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                    copy: false,
                }
            })
            .state('app.editUsersGroup', {
                url: 'user-groups/edit',
                views: {
                    main: {
                        template: '<new-user-group></new-user-group>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/user-groups/new/toolbar/toolbar.tpl.html',
                        controller: 'NewUserGroupToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.translationTool', {
                url: 'translation-tool',
                views: {
                    main: {
                        template: '<translation-tool></translation-tool>',
                    },
                },
            })
            .state('app.shareParkDiscounts', {
                url: 'share-park/discounts',
                views: {
                    main: {
                        template: '<share-park-discounts></share-park-discounts>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.newDiscount', {
                url: 'share-park/discounts/new',
                views: {
                    main: {
                        template: '<new-discount></new-discount>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/pricing/discounts/new/toolbar/toolbar.tpl.html',
                        controller: 'NewDiscountToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editDiscount', {
                url: 'share-park/discounts/edit',
                views: {
                    main: {
                        template: '<new-discount></new-discount>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/pricing/discounts/new/toolbar/toolbar.tpl.html',
                        controller: 'NewDiscountToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.shareParkProducts', {
                url: 'share-park/products',
                views: {
                    main: {
                        template: '<share-park-products></share-park-products>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.newProduct', {
                url: 'share-park/products/new',
                views: {
                    main: {
                        template: '<new-product></new-product>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/pricing/products/new/toolbar/toolbar.tpl.html',
                        controller: 'NewProductToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editProduct', {
                url: 'share-park/products/edit',
                views: {
                    main: {
                        template: '<new-product></new-product>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/pricing/products/new/toolbar/toolbar.tpl.html',
                        controller: 'NewProductToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.shareParkTariffs', {
                url: 'share-park/tariffs',
                views: {
                    main: {
                        template: '<share-park-tariffs></share-park-tariffs>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.newTariff', {
                url: 'share-park/tariffs/new',
                views: {
                    main: {
                        template: '<new-tariff></new-tariff>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/pricing/tariffs/new/toolbar/toolbar.tpl.html',
                        controller: 'NewTariffToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editTariff', {
                url: 'share-park/tariffs/edit',
                views: {
                    main: {
                        template: '<new-tariff></new-tariff>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/pricing/tariffs/new/toolbar/toolbar.tpl.html',
                        controller: 'NewTariffToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.shareParkTariffModels', {
                url: 'share-park/tariff-models',
                views: {
                    main: {
                        template: '<share-park-tariff-models></share-park-tariff-models>',
                    },
                },
                params: {
                    paginationData: null
                }
            })
            .state('app.newTariffModel', {
                url: 'share-park/tariff-models/new',
                views: {
                    main: {
                        template: '<new-tariff-model></new-tariff-model>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/pricing/tariff-models/new/toolbar/toolbar.tpl.html',
                        controller: 'NewTariffModelToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'new',
                }
            })
            .state('app.editTariffModel', {
                url: 'share-park/tariff-models/edit',
                views: {
                    main: {
                        template: '<new-tariff-model></new-tariff-model>',
                    },
                    toolbar: {
                        templateUrl: '/assets/views/share-park/pricing/tariff-models/new/toolbar/toolbar.tpl.html',
                        controller: 'NewTariffModelToolbarController',
                        controllerAs: '$ctrl'
                    },
                },
                params: {
                    data: null,
                    paginationData: null,
                    type: 'edit',
                }
            })
            .state('app.multiGuide', {
                url: 'share-park/multi-guide',
                views: {
                    main: {
                        template: '<multi-guide></multi-guide>',
                    },
                },
            })
        ;

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });

        $authProvider.loginUrl = '/api/login';
    }
}());
