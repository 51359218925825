(function() {
    'use strict';

    angular.module('beacon.app')
        .filter('unsafe', unsafeHTML)
        .filter('htmlToPlaintext', htmlToPlaintext)
        .filter('firstNotEmptyDictValue', firstNotEmptyDictValue)
        .filter('utcFormat', utcFormat)
        .filter('lngDefault', lngDefault)
        .filter('lng', lng)
        .filter('price', price)

    function unsafeHTML($sce) {
        return function(value) {
            //  trustAsHtml method absolutely expects a string value, that's why added coercion to the String
            return $sce.trustAsHtml('' + value);
        }
    };

    function htmlToPlaintext() {
        return function(text) {
            return text ? String(text).replace(/<[^>]+>/gm, '') : '';
        };
    }

    function firstNotEmptyDictValue() {
        return function(object) {
            return Object.values(object).filter((value) => !!value)[0];
        };
    }

    function utcFormat() {
        /**
         * @param {string} date 
         * @param {string} format - moment date format
         */
        return function (date, format) {
            return date ? moment.utc(date).local().format(format) : '';
        };
    }

    function lngDefault() {
        return function (data) {
            try {
                const translations = angular.isString(data)
                    ? JSON.parse(data)
                    : data;
                const languageIds = Object.keys(translations);
                const defaultLngId = languageIds[0];
                return translations[defaultLngId];
            } catch(error) {
                return data;
            }
        };
    }

    function lng(TranslateService) {
        return function (value) {
            return TranslateService.getCurrentUiLanguageTranslation(value);
        };
    }

    function price($translate) {
        return function (value) {
            const currentUiLang = $translate.use();
            const valueStr = value.toFixed(2);
            return currentUiLang === 'de'
                ? valueStr.replace('.', ',')
                : valueStr;
        };
    }

}());
