(function() {
    'use strict';

    angular.module('beacon.app')
        .service('VoucherService', VoucherService);

    function VoucherService(Restangular, $http) {

        /**
         * Get list
         *
         * @returns {array}
         */
        function getList() {
            return Restangular.all('vouchers').getList();
        }

        /**
         * Get voucher type
         *
         * @returns {array}
         */
        function getVoucherType(voucherId) {
            return $http.get(`/api/voucher/type/${voucherId}`);
        }

        /**
         * Create
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function create(data) {
            return Restangular.all('vouchers')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Update
         *
         * @param {object} data - FormData object
         * @returns {*}
         */
        function update(data) {
            data.append('_method', 'PUT');
            return Restangular.all('vouchers')
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        /**
         * Delete
         *
         * @param {number} id
         * @returns {*}
         */
        function remove(id) {
            const formData = new FormData();
            formData.append('_method', 'DELETE');
            return Restangular.all('vouchers/' + id)
                .withHttpConfig({transformRequest: angular.identity})
                .post(formData, {}, {'Content-Type': undefined});
        }



        /**
         * Uploading voucher codes
         *
         * @param {object} data
         * @param {number} id
         * @returns {*}
         */
        function uploadCodes(data, id) {
            return Restangular.all('/vouchers/send_codes/' + id)
                .withHttpConfig({transformRequest: angular.identity})
                .post(data, {}, {'Content-Type': undefined});
        }

        return {
            getList,
            create,
            update,
            remove,
            uploadCodes,
            getVoucherType
        }
    }
})();