(function() {
    'use strict';

    angular.module('beacon.app')
        .service('PopupService', popupService);

    function popupService($uibModal, $sce) {
        var modalDefaults = {
                backdrop: 'static',
                keyboard: true,
                modalFade: true
            },
            modalOptions = {
                closeButtonText: 'CLOSE',
                actionButtonText: 'OK'
            };

        return {
            show,
            showConfirmationPopup,
            showAlertPopup,
            showImagePopup,
        };

        /**
         * Shows confirmation popup
         *
         * @param {object} data
         * @param {string} data.text
         * @param {string} data.okButtonText
         * @param {string} data.cancelButtonText
         * @param {callback} callback
         */
        function showConfirmationPopup(data, callback = null) {
            var confirmationPopup = this.show({
                templateUrl: '/assets/views/common/popups/confirmation/confirmation.tpl.html',
                controller: 'ConfirmationPopupController',
                controllerAs: 'confirmationPopupController',
                windowClass: 'confirmationPopup',
                keyboard: false,
                resolve: {
                    data: function() {
                        return data;
                    }
                }
            });

            if (callback) {
                confirmationPopup.then(() => callback());
            } else {
                return confirmationPopup;
            }
        }

        /**
         * Shows alert popup
         *
         * @param {string} data.text
         * @param {boolean} [data.html]
         * @param {string} data.okButtonText
         */
        function showAlertPopup(data) {
            if (data.html) {
                data.text = $sce.trustAsHtml(data.text);
            }
            return this.show({
                templateUrl: '/assets/views/common/popups/info/info.tpl.html',
                controller: 'InfoPopupController',
                controllerAs: 'infoPopupController',
                windowClass: 'infoPopup',
                keyboard: true,
                resolve: {
                    data: function() {
                        return data;
                    }
                }
            });
        }

        /**
         * Shows image preview popup
         *
         * @param {string} imageSrc
         */
        function showImagePopup(imageSrc) {
            this.show({
                templateUrl: '/assets/views/common/popups/image-preview/image-preview.tpl.html',
                controller: 'ImagePreviewPopupController',
                controllerAs: 'imagePreviewPopupController',
                windowClass: 'imagePreviewPopup',
                keyboard: false,
                resolve: {
                    data: function() {
                        return {
                            okButtonText: 'Ok',
                            imageUrl: imageSrc
                        };
                    }
                }
            });
        }

        /**
         * Shows popup
         *
         * @param {object} customModalDefaults
         * @param {object} customModalOptions
         * @returns {object}
         */
        function show(customModalDefaults, customModalOptions) {
            var tempModalDefaults = {},
                tempModalOptions = {},
                instance;

            if (!customModalDefaults) {
                customModalDefaults = {};
            }

            angular.extend(tempModalDefaults, modalDefaults, customModalDefaults);
            angular.extend(tempModalOptions, modalOptions, customModalOptions);

            if (!tempModalDefaults.controller) {
                tempModalDefaults.controller = defaultController;
            }

            instance = $uibModal.open(tempModalDefaults);

            return instance.result;
        }

        function defaultController($scope, $uibModalInstance) {
            const vm = this;

            vm.modalOptions = {};
            vm.ok = function(result) {
                $uibModalInstance.close(result);
            };
            vm.close = function() {
                $uibModalInstance.dismiss('cancel');
            };
        }
    }
})();
