(function() {
    'use strict';

    angular.module('trmTimetableHafas')
        .component('realTime', {
            templateUrl: '/screen/views/components/timetable-hafas/elements/real-time/real-time.tpl.html',
            controller: RealTimeController,
            bindings: {
                journey: '<',
                element: '<',
                isConnectionStable: '<',
                color: '<',
                odd: '<',
                maxHeight: '<',
                colorScheme: '<',
            }
        });

    /**
     * @property {Journey} journey
     * @property {TimetableHafasElement} element
     * @property {boolean} realtimeIcon - shows additional realtime icon
     * @property {string} color
     * @property {number} odd - odd or even
     * @property {string} maxHeight - i.e. "100px"
     *
     * @property {string} displayValue
     * @property {boolean} delayed
     *
     * @param {$rootScope.Scope} $scope
     * @param $moment
     * @param $interval
     * @param {TimeFormatService} TimeFormatService
     *
     * @constructor
     */
    function RealTimeController(
        $scope,
        $moment,
        $interval,
        TimeFormatService,
    ) {
        const $ctrl = this;

        let isNowInterval = null;

        $ctrl.$onInit = () => {
            $ctrl.behavior = $ctrl.element.behavior;
            $ctrl.format = $ctrl.element.timeFormat;
            $ctrl.textAlign = $ctrl.element.textAlign;
            $ctrl.nowTimeout = $ctrl.element.timeNow;
            $ctrl.isCancelled = $ctrl.journey.isCancelled;
            $ctrl.countdownThreshold = $ctrl.element.countdownThreshold;
            $ctrl.isDelayedTimeHighlight = $ctrl.element.isDelayedTimeHighlight;
            $ctrl.value = $ctrl.journey.getRealTime() || $ctrl.journey.getScheduledTime();
            $ctrl.scheduledValue = $ctrl.journey.getScheduledTime();
            $ctrl.textScaleParams = {
                scale: $ctrl.element.textScale,
                nowScale: $ctrl.element.nowScale,
                maxHeight: $ctrl.maxHeight,
            };

            if (!$ctrl.value) {
                $ctrl.displayValue = null;
                return;
            }

            if ($ctrl.scheduledValue) {
                $ctrl.delayed = $ctrl.value.diff($ctrl.scheduledValue, 'minutes') !== 0;
            }

            $ctrl.textColor = $ctrl.delayed && $ctrl.isDelayedTimeHighlight
                ? $ctrl.element.delayedHighlightColors[$ctrl.colorScheme]
                : $ctrl.color;

            _setIsNowValue();
            isNowInterval = $interval(_setIsNowValue, 1000);

            // hide if scheduled and real time is the same (behavior should be 'auto')
            if ($ctrl.scheduledValue && $ctrl.behavior === 'auto') {
                const minutesDiff = $ctrl.value.diff($ctrl.scheduledValue, 'minutes');

                if (minutesDiff === 0) {
                    $ctrl.displayValue = null;
                    return;
                }
            }

            switch ($ctrl.format) {
                case 'countdown':
                    $ctrl.displayValue = TimeFormatService.getCountdownMinutes($ctrl.value, $ctrl.countdownThreshold);
                    break;
                case 'hh:mm':
                    $ctrl.displayValue = $ctrl.value.format('HH:mm');
                    break;
                case 'relative':
                    const minutesDiff = $ctrl.value.diff($ctrl.scheduledValue, 'minutes');

                    if (minutesDiff === 0) {
                        $ctrl.displayValue = $ctrl.value.format('HH:mm');
                    } else {
                        $ctrl.displayValue = minutesDiff > 0 ? '+' + minutesDiff : minutesDiff;
                    }
                    break;
            }
        }

        $ctrl.showRealtime = () => {
            return $ctrl.element.realtimeIndicator && $ctrl.journey.isRealTime() && $ctrl.isConnectionStable;
        }

        function _onDestroy() {
            if (isNowInterval) {
                $interval.cancel(isNowInterval);
            }
        }

        function _setIsNowValue() {
            $ctrl.secondsDiff = $ctrl.value.diff($moment(), 'seconds');
            $ctrl.isNow = $ctrl.nowTimeout && $ctrl.secondsDiff <= $ctrl.nowTimeout;
        }

        $scope.$on('$destroy', _onDestroy);
    }
})();
