(function () {
    'use strict';

    angular.module('beacon.app').component('newCustomerCommunication', {
        templateUrl:
            '/assets/views/share-park/customers/customer-communication/new/new-customer-communication.tpl.html',
        controller: NewCustomerCommunicationController,
    });

    function NewCustomerCommunicationController(
        $scope,
        $state,
        $translate,
        ModelsFactory,
        StatesFactory,
        TEXT_ANGULAR_OPTIONS_EXTENDED,
        CustomersCacheFactory,
        ProfilesHelper,
        $mdConstant,
        CustomerCommunicationService,
        $stateParams,
        PopupService,
        LanguageService
    ) {
        const vm = this;

        vm.$onInit = init;

        vm.availableTabs = [
            { name: 'PROFILES', index: 1 },
            { name: 'USERS', index: 2 },
            { name: 'EMAIL_ADDRESS', index: 3 },
        ];

        vm.selectedTabIndex = 1;
        vm.changeTab = changeTab;

        vm.chipsSeparators = [$mdConstant.KEY_CODE.ENTER, $mdConstant.KEY_CODE.SEMICOLON, $mdConstant.KEY_CODE.COMMA];

        vm.setLengthProfiles = setLengthProfiles;
        vm.saveEmails = saveEmails;
        vm.filteredCustomers = [];
        vm.filterCustomers = filterCustomers;
        vm.separateMail = separateMail;

        const { decodeCustomerProfile } = ProfilesHelper;
        vm.customersAll = [];
        vm.profiles = [];
        vm.selectedProfiles = {
            selected: {},
            customers: [],
            length: 0,
        };

        vm.customersListData = {
            columns: [
                {
                    name: 'EMAIL',
                    width: '55',
                    title: customer => customer.emailAddress,
                },
                {
                    name: 'NAME',
                    width: '55',
                    title: customer => customer.nameFirst + ' ' + customer.nameLast,
                },
            ],
            header: false,
        };
        vm.selectedCustomers = [];

        vm.state = StatesFactory.ShareParkCustomerComunicationStates.refresh();
        vm.TEXT_ANGULAR_OPTIONS_EXTENDED = TEXT_ANGULAR_OPTIONS_EXTENDED;
        vm.onEditorModeChange = onEditorModeChange;
        vm.parameters = ['nameFirst', 'nameLast'];
        vm.selectParams = selectParams;
        vm.selectedParams = {};
        vm.params = [
            { name: 'First Name', sign: '{{nameFirst}}' },
            { name: 'Last Name', sign: '{{nameLast}}' },
            { name: 'Sender', sign: '{{sender}}' },
            { name: 'Subject', sign: '{{subject}}' },
            { name: 'Sent Date', sign: '{{sent_date}}' },
            { name: 'User Emails', sign: '{{email}}' },
        ]

        vm.state.type = $stateParams.type;

        function init() {
            vm.invitationData = $stateParams.data ? $stateParams.data : new ModelsFactory.CarParkCustomerCommunication();

            LanguageService.getLanguages().then(response => {
                vm.langsArray = response.plain();
                if (!vm.invitationData.language) {
                    vm.invitationData.language = vm.langsArray[0].id;
                }
                changeFormatLang();
            });
            getProfiles();
        }

        function separateMail() {
            vm.invitationData.target_users.emails = vm.invitationData.target_users.emails.map(mail => {
                if (mail.includes(',')) {
                    return mail.split(',')
                } else if (mail.includes(';')) {
                    return mail.split(';')
                } else {
                    return mail;
                }
            }).flat();
        }

        function changeFormatLang() {
            vm.langsArray.forEach(lang => {
                if (vm.invitationData.language === lang.code) {
                    vm.invitationData.language = lang.id;
                }
            })
        }

        function selectParams() {
            let content = document.querySelector('#emailText [contenteditable="true"]');
            content.focus();

            try {
                var selection = window.getSelection(),
                    range = selection.getRangeAt(0),
                    temp = document.createElement('div'),
                    insertion = document.createDocumentFragment();

                temp.innerHTML = vm.selectedParams.sign;

                while (temp.firstChild) {
                    insertion.appendChild(temp.firstChild);
                }

                range.deleteContents();
                range.insertNode(insertion);
            } catch (z) {
                try {
                    document.selection.createRange().pasteHTML(vm.selectedParams.sign);
                } catch (z) { }
            }

            vm.invitationData.email_body[vm.invitationData.language] = content.innerHTML;
        }

        function getProfiles() {
            CustomersCacheFactory.getAll().then(response => {
                vm.customersAll = response.plain().filter(customer => customer.profile).map(customer => {
                    customer.profile = decodeCustomerProfile(customer.profile);
                    return customer;
                });

                vm.profiles = extractProfiles();
                vm.filteredCustomers = vm.customersAll;
                $stateParams.data && initInvitationData();
            });
        }

        function extractProfiles() {
            const profilesMap = {};
            vm.customersAll.forEach(customer => {
                const profile = customer.profile;
                if (profile) {
                    if (!profilesMap[profile.id]) {
                        profilesMap[profile.id] = customer.profile;
                        profilesMap[profile.id].usersCount = 1;
                    } else {
                        profilesMap[profile.id].usersCount++;
                    }
                }
            });
            return Object.values(profilesMap);
        }

        function initInvitationData() {
            vm.selectedProfiles.selected = Object.fromEntries(
                vm.invitationData.target_users.profileRefs.map(profileRef => [profileRef, true])
            );
            vm.setLengthProfiles();
            vm.selectedCustomers = vm.customersAll.filter(customer =>
                vm.invitationData.target_users.guids.includes(customer.guid)
            );
            if (vm.invitationData.start_date) vm.invitationData.start_date = moment().utc(vm.invitationData.start_date);
            if (vm.invitationData.sent_date) vm.invitationData.sent_date = moment().utc(vm.invitationData.sent_date);
        }

        function changeTab(newTab) {
            vm.selectedTabIndex = newTab;
        }

        /**
         * Customer's name
         *
         * @param customer
         * @returns {string}
         * @private
         */
        function _customerName(customer) {
            return `${customer.nameFirst} ${customer.nameLast}`;
        }

        /**
         * Customer's email address
         *
         * @param customer
         * @returns {string}
         * @private
         */
        function _customerEmail(customer) {
            return customer.emailAddress;
        }

        /**
         * Checks if search value is in customer number plates
         * @param { Object } customer
         * @param { string } searchValue
         * @return { boolean }
         */
        function searchByCustomerVehicles(customer, searchValue) {
            return customer.registeredVehicles.some(vehicle => {
                if (!vehicle.capVehicleRegistration) {
                    return false;
                }

                const numberPlate = vehicle.capVehicleRegistration.numberPlate || '';
                return numberPlate.toUpperCase().includes(searchValue.toUpperCase());
            });
        }

        function filterCustomers() {
            vm.filteredCustomers = vm.searchValue.length
                ? vm.customersAll.filter(customer => {
                    return (
                        _customerName(customer).toUpperCase().includes(vm.searchValue.toUpperCase()) ||
                        _customerEmail(customer).toUpperCase().includes(vm.searchValue.toUpperCase()) ||
                        searchByCustomerVehicles(customer, vm.searchValue)
                    );
                })
                : vm.customersAll;
        }

        function setLengthProfiles() {
            vm.selectedProfiles.length = Object.values(vm.selectedProfiles.selected).filter(val => val).length;

            vm.selectedProfiles.customers = Object.entries(vm.selectedProfiles.selected)
                .map(profile => {
                    return profile[1] === true
                        ? vm.customersAll.filter(customer => customer.profile.id === Number(profile[0]))
                        : [];
                })
                .flat();

        }


        function saveEmails(isSend, isTestEmail) {
            let content = vm.invitationData.email_body[vm.invitationData.language];
            if (content && !content.includes('sans-serif')) {
                if (content.slice(0, 5) === '<div>') {
                    content = content.replace('<div>', '<div style="font-family: sans-serif;">')
                } else {
                    content = ('<div style="font-family: sans-serif;">' + content + '</div>');
                }
            }
            vm.invitationData.email_body[vm.invitationData.language] = content;

            let guids = [];
            vm.selectedCustomers.map(customer => {
                customer.guid && guids.push(customer.guid);
            });
            vm.invitationData.target_users.guids = guids;

            vm.invitationData.target_users.profileRefs = Object.keys(vm.selectedProfiles.selected).filter(
                profileRef => vm.selectedProfiles.selected[profileRef]
            );

            vm.isFutureSending = new Date() < new Date(vm.invitationData.start_date);
            let message = vm.isFutureSending ? 'YOUR_EMAIL_WIIL_BE_SENT' : 'DO_YOU_REALLY_WANT_TO_SEND_EMAIL';

            const cloneInvitationData = JSON.parse(JSON.stringify(vm.invitationData))
            cloneInvitationData.language = vm.langsArray.filter(lang => lang.id == vm.invitationData.language)[0].code

            if (!isTestEmail) {
                CustomerCommunicationService.seveBulkEmails(cloneInvitationData).then(response => {
                    if (isSend) {
                        $translate(message, {
                            count: vm.invitationData.target_users.emails.length
                                + vm.selectedCustomers.length + vm.selectedProfiles.customers.length,
                            sentDate: moment(vm.invitationData.start_date).format('YYYY-MM-DD'),
                            time: moment(vm.invitationData.start_date).format('H:mm '),
                        })
                            .then(text =>
                                PopupService.showConfirmationPopup({
                                    text,
                                    okButtonText: 'OK',
                                    cancelButtonText: 'CANCEL',
                                })
                            )
                            .then(_ =>
                                CustomerCommunicationService.sendBulkEmails(response.data.id).catch(err => {
                                    PopupService.showAlertPopup({
                                        text: 'ERROR_WHILE_SENDING_EMAIS',
                                        okButtonText: 'OK',
                                    });
                                    throw err;
                                })
                            )
                            .then(_ =>
                                PopupService.showAlertPopup({
                                    text: 'EMAIS_SENT_SUCCESSFULLY',
                                    okButtonText: 'OK',
                                })
                            )
                            .then(_ => $state.go('app.shareParkCustomerCommunication'));
                    } else {
                        $state.go('app.shareParkCustomerCommunication');
                    }
                });
            } else {
                CustomerCommunicationService.sendTestEmail(cloneInvitationData)
                    .catch(err => {
                        PopupService.showAlertPopup({
                            text: 'ERROR_WHILE_SENDING_EMAIS',
                            okButtonText: 'OK',
                        });
                        throw err;
                    })
            }
        }

        function onEditorModeChange() {
            if (vm.useHtmlEditor) {
                vm.invitationData.email_body[vm.invitationData.language] = window.html_beautify(
                    vm.invitationData.email_body[vm.invitationData.language]
                );
            }
        }

        $scope.$watch(
            () => vm.state.save,
            newValue => newValue && saveEmails()
        );

        $scope.$watch(
            () => vm.state.send,
            newValue => newValue && saveEmails(true, false)
        );
    }
})();
