(function() {
    'use strict';

    angular.module('beacon.app')
        .component('newTenant', {
            templateUrl: '/assets/views/tenants/tabs/tenants-tab/new/new-tenant.tpl.html',
            controller: NewTenantController,
            bindings: {}
        });

    function NewTenantController (
        $scope,
        $state,
        $stateParams,
        tenantAddState,
        LOCATION_TYPES,
        APP_MODES,
        TenantDataService
    ) {
        const vm = this;

        const locationTypes = LOCATION_TYPES || [];

        // public properties
        vm.state = tenantAddState;
        vm.state.type = $stateParams.type;
        vm.data = $stateParams.data || {};

        vm.MODES = Object.values(APP_MODES);
        vm.possiblePrimeModes = vm.MODES;

        vm.TABS = {
            TENANT: {
                id: 1,
                name: 'TENANT',
            },
            PERMISSIONS: {
                id: 2,
                name: 'PERMISSIONS',
            },
            LOCATIONS: {
                id: 3,
                name: 'LOCATIONS',
            },
            SETTINGS: {
                id: 4,
                name: 'SETTINGS',
            }
        };

        vm.TABS_ARR = Object.values(vm.TABS);
        vm.selectedTabId = vm.TABS.TENANT.id;

        vm.item = {
            tenantLocations: {
                isChecked: isCheckedLocation,
                toggleCheckbox: toggleLocation,
                items: processLocations(vm.data.locationsAll, vm.data.tenant),
                titleProp: 'name',
                groupByValue : 'region',
                orderByValue: 'name',
                groups: [
                    {
                        name: "LOCATION_TYPES",
                        items: locationTypes,
                        idProp: "id",
                        titleProp: "name",
                        filterFunction: (checkboxItem, selectedGroupItems)=> {
                            var isVisible = false;
                            _.forEach(selectedGroupItems, (groupItem)=>{
                                if (groupItem && checkboxItem[groupItem] && checkboxItem[groupItem] > 0) {
                                    isVisible = true;
                                    return false;
                                }
                            });
                            return isVisible;
                        }
                    },
                    {
                        name: "LOCATION_GROUPS",
                        items: _.uniqBy(vm.data.locationsAll, 'region'),
                        idProp: "region",
                        titleProp: "region",
                        filterFunction: (checkboxItem, selectedGroupItems)=>
                            _.indexOf(selectedGroupItems, checkboxItem.region)!==-1
                    }
                ]
            }
        };

        vm.$onInit = onInit;

        vm.isCheckedPermission = isCheckedPermission;
        vm.togglePermission = togglePermission;
        vm.toggleMode = toggleMode;
        vm.isModeEnabled = isModeEnabled;

        /**
         * On Init
         */
        function onInit() {
            const tenant = vm.data.tenant;

            if(tenant.corporateColours) {
                tenant.colors = createColorsModel(tenant.corporateColours);
            }

            if(tenant.homeLocation) {
                tenant.location = createLocationModel(tenant.homeLocation);
            }

            bindWatchers();
            updatePrimeModes();
        }

        /**
         * Create tenant colors model
         * @param {string} corporateColours - Tenant corporate colors string
         * @returns {object} - Tenant colors model
         */
        function createColorsModel(corporateColours) {
            const colors = corporateColours.split(',');

            return {
                primary: colors[0],
                dark: colors[1],
                accent: colors[2]
            };
        }

        /**
         * Create tenant location model
         * @param {string} homeLocation = Tenant home location coordinates and zoom string
         * @returns {object} Tenant home location model
         */
        function createLocationModel(homeLocation) {
            const locationData = homeLocation.split(',');

            return {
                coordinates: {
                    latitude: parseFloat(locationData[0]),
                    longitude: parseFloat(locationData[1]),
                },
                zoom: Number(locationData[2])
            };
        }

        /**
         * Stringify colors
         * @param {object} colors Tenant colors model
         * @returns {string} Tenants colors string
         */
        function stringifyColors(colors) {
            return Object.keys(colors)
                .map(key => colors[key])
                .join(',');
        }

        /**
         * Stringify location
         * @param {object} location Tenant location model
         * @returns {string} Tenant location string
         */
        function stringifyLocation(location) {
            return Object.keys(location.coordinates)
                .map(key => location.coordinates[key])
                .concat(location.zoom)
                .join(',');
        }

        /**
         * Is Permission Checked
         * @param permission
         * @returns {boolean}
         */
        function isCheckedPermission(permission) {
            return _.findIndex(vm.data.tenant.permission_tenant, {'permission_id': permission.id}) > -1;
        }

        /**
         * Toggle Permission
         * @param permission
         */
        function togglePermission(permission) {
            const permissionTenant = vm.data.tenant.permission_tenant;
            const permissionIndex = _.findIndex(permissionTenant, {'permission_id': permission.id});

            permissionIndex > -1
                ? permissionTenant.splice(permissionIndex, 1)
                : permissionTenant.push({'tenant_id': vm.data.tenant.id, 'permission_id': permission.id});
        }

        /**
         * Is Location Checked
         * @param location
         * @returns {boolean}
         */
        function isCheckedLocation(location) {
            return _.findIndex(vm.data.tenant.location_tenant, {'location_id': location.id}) > -1;
        }

        /**
         * Toggle Location
         * @param location
         */
        function toggleLocation(location) {
            if (location.disabled) {
                return;
            }

            const locationTenant = vm.data.tenant.location_tenant;
            const locationIndex = _.findIndex(locationTenant, {'location_id': location.id});

            locationIndex > -1
                ? locationTenant.splice(locationIndex, 1)
                : locationTenant.push({'tenant_id': vm.data.tenant.id, 'location_id': location.id});
        }

        /**
         * Save action callback
         *
         */
        function onFinish() {
            const tenant = vm.data.tenant;
            tenant.corporateColours = stringifyColors(tenant.colors);
            tenant.homeLocation = stringifyLocation(tenant.location);
            tenant.params = JSON.stringify(tenant.params);

            const formData = new FormData();
            formData.append('tenant', JSON.stringify(tenant));
            formData.append('image', tenant.image);

            const success = () => $state.go('app.tenants');
            const error = console.error.bind(console);

            vm.state.type === 'new'
                ? TenantDataService.createTenant(formData)
                    .then(success)
                    .catch(error)
                : TenantDataService.updateTenant(tenant, formData)
                    .then(success)
                    .catch(error);
        }

        /**
         * Enable save button
         */
        function canFinish(){
            const tenant = vm.data.tenant;

            vm.state.canFinish = tenant.name
                && tenant.groupName
                && tenant.company
                && tenant.supportedLanguages
                && tenant.supportedUiLanguages
                && tenant.location
                && vm.tenantModesValid
                && tenant.dynamicContentTemplates
                && tenant.allowedTimetableOptions
                && tenant.primeMode;
        }

        /**
         * Process locations, to prevent assigning screens to many tenants
         *
         * @param locations
         * @param tenant
         * @returns {*}
         */
        function processLocations(locations, tenant) {
            return locations.map(location => {
                const isScreen = location.controllerRef > 0;
                const isAssignedToAnotherTenant = location.tenant.find(item => item.id !== tenant.id);
                const isAssignedToCurrentTenant = location.tenant.find(item => item.id === tenant.id);
                location.disabled = isScreen && isAssignedToAnotherTenant && !isAssignedToCurrentTenant;
                location.checkboxHint = isScreen && isAssignedToAnotherTenant
                    ? location.tenant.map(tenant => tenant.name).join(', ')
                    : null;
                return location;
            });
        }

        function toggleMode(modeId) {
            const index = vm.data.tenant.allowedModes.indexOf(modeId);
            if (index === -1) {
                vm.data.tenant.allowedModes.push(modeId);
            } else {
                vm.data.tenant.allowedModes.splice(index, 1)
            }

            updatePrimeModes();
        }

        function updatePrimeModes() {
            vm.possiblePrimeModes = vm.MODES.filter(mode => vm.data.tenant.allowedModes.includes(mode.id));

            if (vm.possiblePrimeModes.every(mode => mode.id !== vm.data.tenant.primeMode)) {
                vm.data.tenant.primeMode = vm.possiblePrimeModes[0]
                    ? vm.possiblePrimeModes[0].id
                    : null;
            }
        }

        /**
         *
         * @param modeId
         * @return {boolean}
         */
        function isModeEnabled(modeId) {
            return vm.data.tenant.allowedModes.includes(modeId);
        }

        function validateTenantModes(allowedModes) {
            vm.tenantModesValid = allowedModes && allowedModes.length;
            canFinish();
        }

        /**
         * Bind watchers
         */
        function bindWatchers() {
            const tenant = vm.data.tenant;

            $scope.$watch(
                () => vm.state.finish,
                newValue => newValue && onFinish()
            );

            $scope.$watch(
                () => tenant.name,
                () => canFinish()
            );

            $scope.$watch(
                () => tenant.company,
                () => canFinish()
            );

            $scope.$watch(
                () => tenant.groupName,
                () => canFinish()
            );

            $scope.$watch(
                () => tenant.location,
                () => canFinish()
            );

            $scope.$watch(
                () => tenant.allowedModes,
                validateTenantModes,
                true
            );

            $scope.$watch(
                () => tenant.supportedLanguages,
                newVal => {
                    tenant.supportedLanguages = newVal.replace(' ', '');
                    canFinish();
                }
            );

            $scope.$watch(
                () => tenant.supportedUiLanguages,
                newVal => {
                    tenant.supportedUiLanguages = newVal.replace(' ', '');
                    canFinish();
                }
            );

            $scope.$watchGroup([
                    () => tenant.dynamicContentTemplates,
                    () => tenant.allowedTimetableOptions,
                ],
                () => canFinish(),
                true,
            );
        }
    }
}());
