(function() {
    'use strict';

    angular.module('beacon.app')
        .service('TranslateService', TranslateService);

    function TranslateService(
        $translate,
    ) {
        return {
            getCurrentUiLanguageTranslation,
        }

        function getCurrentUiLanguageTranslation(value) { 
            try {
                const decoded = typeof value === 'string' 
                    ? JSON.parse(value) 
                    : value;

                if (typeof decoded !== 'object') {
                    return value;
                }

                const defaultLng = Object.keys(decoded)[0];
                const currentUiLang = $translate.use();

                if (!defaultLng) {
                    return ''
                }

                return replaceNewLine(decoded[currentUiLang] || decoded[defaultLng]);
            } catch (error) {
                return value;
            }
                
            function replaceNewLine(value) {
                return value.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
        }
    }
})();